import React from 'react'
import {withRouter} from 'react-router'
import QrScanFormView from "../views/QrScanFormView"
import QrReader from "react-qr-reader"
import {earnsService} from "../services/earns.service";
import translate, {translateValue} from "../i18n/translate";

var deviceDetect = require('device-detect')();


class QrScanFormController extends React.Component {
    state = {
        code: '',
        cameraError: 'none',

        codeInvalidError: 'none',
        earnInactiveError: 'none',
        codeInactiveError: 'none',
        codeLimitError: 'none',
        codeTimeError: 'none',
        serverError: 'none',
    }

    constructor(props) {
        super(props)
        this.handleScan = this.handleScan.bind(this)
    }

    componentWillMount() {
        if ((deviceDetect.device === 'iPhone' | deviceDetect.device === 'iPad' | deviceDetect.device === 'iPod')
            && deviceDetect.browser !== 'Safari') {
            this.setState({
                cameraError: 'block'
            })
        }
    }

    render() {
        return (
            <QrScanFormView>
                <i18n-scan-the-qr-code>{translate('qrScan_scanTheQrCode')}</i18n-scan-the-qr-code>
                <camera>
                    <QrReader
                        onError={this.handleError}
                        onScan={this.handleScan}
                        style={{width: "100%"}}
                    />
                </camera>
                <code-error style={{display: this.state.codeError}}>{translate('qrScan_codeError')}</code-error>
                <camera-error style={{display: this.state.cameraError}}>{translate('qrScan_cameraError')}</camera-error>

                <code onChange={this.enterCode} value={this.state.code}/>
                <verify onClick={this.verify} {...translateValue('qrScan_verify')}/>
                <code-invalid-error style={{display: this.state.codeInvalidError}}>{translate('qrScan_codeInvalidError')}</code-invalid-error>
                <earn-inactive-error style={{display: this.state.earnInactiveError}}>{translate('qrScan_earnInactiveError')}</earn-inactive-error>
                <code-inactive-error style={{display: this.state.codeInactiveError}}>{translate('qrScan_codeInactiveError')}</code-inactive-error>
                <code-limit-error style={{display: this.state.codeLimitError}}>{translate('qrScan_codeLimitError')}</code-limit-error>
                <code-time-error style={{display: this.state.codeTimeError}}>{translate('qrScan_codeTimeError')}</code-time-error>
                <server-error style={{display: this.state.serverError}}>{translate('qrScan_serverError')}</server-error>
            </QrScanFormView>
        )
    }

    handleScan(data) {
        let prefix = 'ecocoin-earn:'

        if (!data || !data.startsWith(prefix)) {
            this.setState({
                codeError: 'block'
            })
            return
        }

        this.setState({
            code: data.substring(prefix.length),
            codeError: 'none'
        })
        this.doVerification()
    }

    handleError = (err) => {
        this.setState({
            cameraError: 'block'
        })
        console.error(err)
    }

    enterCode = (e) => {
        this.setState({
            code: e.target.value
        })
    }

    verify = (e) => {
        console.log("VERIFY CODE", this.state.code)
        this.doVerification();
        e.preventDefault()
    }

    doVerification() {
        this.setState({
            codeInvalidError: 'none',
            earnInactiveError: 'none',
            codeInactiveError: 'none',
            codeLimitError: 'none',
            codeTimeError: 'none',
            serverError: 'none'
        })

        earnsService.claim(this.state.code)
            .then(value => {
                console.log("OK:", value)
                this.props.history.push('/earn-verified')
            })
            .catch(reason => {
                console.log("ERR:", reason)
                switch (reason.code) {
                    case 1202: // TRANSACTION_INVALID_CODE
                        this.setState({
                            codeInvalidError: 'block'
                        })
                        break
                    case 1100: // PRODUCT_NOT_ACTIVE
                        this.setState({
                            earnInactiveError: 'block'
                        })
                        break
                    case 1203: // TRANSACTION_INACTIVE_CODE
                        this.setState({
                            codeInactiveError: 'block'
                        })
                        break
                    case 1200: // TOO_MANY_RECENT_TRANSACTIONS
                    case 1104: // USAGE_RESTRICTION_REACHED
                        this.setState({
                            codeLimitError: 'block'
                        })
                        break
                    case 1101: // PRODUCT_NOT_AVAILABLE (not in earndate)
                        this.setState({
                            codeTimeError: 'block'
                        })
                        break
                    case 1155: // EARN_INVALID_RESTRICTION (error with product setup)
                    default: // SERVER ERROR
                        this.setState({
                            serverError: 'block'
                        })
                        break
                }
            })
    }
}

export default withRouter(QrScanFormController)
