import React from 'react'
import {withRouter} from 'react-router'
import VerificationEmailSentView from "../views/VerificationEmailSentView"
import translate from "../i18n/translate";


class VerificationEmailSentController extends React.Component {
    render() {
        return (
            <VerificationEmailSentView>
                <close onClick={this.onClose}/>
                <i18n-thanks-for-signing-up>{translate('verificationEmailSent_thanks')}</i18n-thanks-for-signing-up>
                <i18n-check-your-email>{translate('verificationEmailSent_checkYourEmail')}</i18n-check-your-email>
            </VerificationEmailSentView>
        )
    }

    onClose = (e) => {
        this.props.history.push('/')
        e.preventDefault()
    }

}

export default withRouter(VerificationEmailSentController)