import React from 'react'
import {withRouter} from 'react-router'
import {authenticationService} from '../services/authentication.service'
import ProfileInfoFormView from "../views/ProfileInfoFormView";
import translate, {translatePlaceholder, translateValue} from "../i18n/translate";


class ProfileInfoFormController extends React.Component {
    state = {
        username: '',
        usernameError: 'none',
        success: 'none'
    }

    componentWillMount() {
        this.setState({
            username: authenticationService.currentUserValue.nickname
        })
    }

    render() {

        return (
            <ProfileInfoFormView>
                <i18n-update-user-name>{translate('profile_updateUserName')}</i18n-update-user-name>
                <username value={this.state.username} onChange={this.setUsername} {...translatePlaceholder('profile_userName')}/>
                <submit onClick={this.submit} {...translateValue('profile_updateUserName')}/>
                <success style={{display: this.state.success}}>{translate('profile_success')}</success>
            </ProfileInfoFormView>
        )
    }

    setUsername = (e) => {
        this.setState({
            username: e.target.value
        })
    }
    submit = (e) => {
        this.setState({
            usernameError: 'none',
            success: 'none',
        })
        let error = false
        if (this.state.username === '') {
            this.setState({
                usernameError: 'block'
            })
            error = true
        }

        if (error === false) {
            console.log("Submit", this.state)
            authenticationService.updateNickname(
                this.state.username
            ).then(response => {
                console.log("UPDATE SUCCESS")
                this.setState({
                    success: 'block'
                })

            })
        }

        e.preventDefault()
    }

}

export default withRouter(ProfileInfoFormController)
