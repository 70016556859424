import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {configureFakeBackend} from './helpers'


require('dotenv').config()

// setup fake backend
if (process.env.REACT_APP_FAKE_BACKEND === 'true') {
    console.log('Using fake backend')
    configureFakeBackend()
}

ReactDOM.render(<App/>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

