/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class EarnSearchFormView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/EarnSearchFormController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = EarnSearchFormView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== EarnSearchFormView ? transformProxies(this.props.children) : {
      'search-input': [],
      'submit': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <form action="/search" className="af-class-search w-clearfix w-form">{map(proxies['search-input'], props => <input type="search" maxLength={256} name="query" placeholder="search" id="search-3" required {...{...props, className: `af-class-search-input af-class-textfield w-input ${props.className || ''}`}}>{props.children}</input>)}{map(proxies['submit'], props => <input type="submit" value="Go" {...{...props, className: `af-class-small-search-button w-button ${props.className || ''}`}}>{props.children}</input>)}</form>
        </span>
      </span>
    )
  }
}

export default EarnSearchFormView

/* eslint-enable */