/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import WalletActivityItemListView from './WalletActivityItemListView'
import WalletRewardListView from './WalletRewardListView'
import BottomNavbarView from './BottomNavbarView'

const scripts = [
  fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js").then(body => body.text()),
  fetch("js/webflow.js").then(body => body.text()),
]

let Controller

class WalletView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/WalletController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = WalletView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== WalletView ? transformProxies(this.props.children) : {
      'username': [],
      'i18n-your-current-balance-is': [],
      'balance': [],
      'profile-link': [],
      'support-link': [],
      'impact-card': [],
      'i18n-your-impact': [],
      'i18n-you-have-saved': [],
      'co2-saved': [],
      'i18n-of-co2e-this-week': [],
      'i18n-recent-activities': [],
      'i18n-your-rewards': [],
      'i18n-click-on-your-purchased-rewards': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-balance-section">
              <div className="af-class-container-9 w-container">
                <div className="af-class-div-block-7">
                  <p className="af-class-paragraph"><span>Hello</span> {map(proxies['username'], props => <span {...props}>{props.children ? props.children : <React.Fragment>&lt;username&gt;</React.Fragment>}</span>)}, <br />{map(proxies['i18n-your-current-balance-is'], props => <span {...props}>{props.children ? props.children : <React.Fragment>Your current balance is</React.Fragment>}</span>)}<br /></p>
                  <p className="af-class-eco-balance-total">{map(proxies['balance'], props => <span {...props}>{props.children ? props.children : <React.Fragment>950</React.Fragment>}</span>)} ECO coins<br /></p>
                </div>
              </div>
              <div className="af-class-container-12 w-container">{map(proxies['profile-link'], props => <a href="profile.html" {...{...props, className: `w-inline-block ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/profile.svg" height={25} alt=" " className="af-class-image-21" /></React.Fragment>}</a>)}</div>
              <div className="af-class-faq-button w-container">{map(proxies['support-link'], props => <a href="frequently-asked-questions.html" {...{...props, className: `w-inline-block ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/Group-4_1.svg" height={25} alt=" " className="af-class-question-button" /></React.Fragment>}</a>)}</div>
            </div>
            <div className="af-class-section-6">
              {map(proxies['impact-card'], props => <div {...{...props, className: `af-class-div-block-8 ${props.className || ''}`}}>{createScope(props.children, proxies => <React.Fragment>
                {map(proxies['i18n-your-impact'], props => <h4 {...{...props, className: `af-class-card-heading ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Your Impact</React.Fragment>}</h4>)}
                <p className="af-class-normal-text">{map(proxies['i18n-you-have-saved'], props => <span {...props}>{props.children ? props.children : <React.Fragment>You have saved</React.Fragment>}</span>)} {map(proxies['co2-saved'], props => <span {...props}>{props.children ? props.children : <React.Fragment><strong>267kg</strong></React.Fragment>}</span>)} {map(proxies['i18n-of-co2e-this-week'], props => <span {...props}>{props.children ? props.children : <React.Fragment>of CO2e this week. </React.Fragment>}</span>)}<br /></p>
              </React.Fragment>)}</div>)}
              <div className="af-class-div-block-8">
                {map(proxies['i18n-recent-activities'], props => <h4 {...{...props, className: `af-class-card-heading ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Recent Activities</React.Fragment>}</h4>)}
                <WalletActivityItemListView.Controller />
              </div>
              <div className="af-class-div-block-8">
                {map(proxies['i18n-your-rewards'], props => <h4 {...{...props, className: `af-class-card-heading ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Your Rewards to Receive</React.Fragment>}</h4>)}
                {map(proxies['i18n-click-on-your-purchased-rewards'], props => <p {...{...props, className: `af-class-normal-text ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>The rewards you have purchased but have yet to receive are displayed here.</React.Fragment>}</p>)}
                <WalletRewardListView.Controller />
              </div>
              <div className="af-class-container-13 w-container" />
            </div>
            <BottomNavbarView.Controller />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default WalletView

/* eslint-enable */