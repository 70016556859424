import React from 'react'
import {withRouter} from 'react-router'
import {authenticationService} from '../services/authentication.service'
import ForgotPasswordFormView from "../views/ForgotPasswordFormView"
import translate, {translatePlaceholder, translateValue} from "../i18n/translate";
import {EMAIL_PATTERN} from "../helpers/patterns"


class ForgotPasswordFormController extends React.Component {
    state = {
        email: '',
        emailError: 'none'
    }

    render() {
        return (
            <ForgotPasswordFormView>
                <email onChange={this.setEmail} {...translatePlaceholder('forgotPassword_email')}/>
                <email-error style={{display: this.state.emailError}}>{translate('forgotPassword_emailError')}</email-error>
                <submit onClick={this.submit} {...translateValue('forgotPassord_submit')}/>
                <sign-in onClick={this.signIn}>{translate('forgotPassword_signIn')}</sign-in>
            </ForgotPasswordFormView>
        )
    }

    setEmail = (e) => {
        this.setState({
            email: e.target.value.trim()
        })
    }

    submit = (e) => {
        this.setState({
            emailError: 'none'
        })
        let error = false
        if (!EMAIL_PATTERN.test(this.state.email)) {
            this.setState({
                emailError: 'block'
            })
            error = true
        }

        if (error === false) {
            console.log("Submit", this.state)
            authenticationService.sendPasswordResetMail(
                this.state.email
            ).then(response => {
                console.log("UPDATE SUCCESS")
                this.props.history.push('/password-reset-email-sent')
            })
        }

        e.preventDefault()
    }

    signIn = (e) => {
        this.props.history.push('/sign-in')
        e.preventDefault()
    }
}

export default withRouter(ForgotPasswordFormController)
