import React from 'react'
import WalletRewardCardController from "./WalletRewardCardController"
import {rewardsService} from "../services/rewards.service"
import {withRouter} from 'react-router'


class WalletRewardListController extends React.Component {
    state = {rewards: []}

    componentWillMount() {
        rewardsService.list().then((rewards) => {
            this.setState({rewards: rewards})
        })
    }

    render() {
        return (
            <div>
                {this.state.rewards
                    .filter((reward) => {
                        return !reward.claimed && !reward.expired
                    })
                    .map((reward) => {
                        return <WalletRewardCardController
                            id={reward.rewardId}
                            key={reward.rewardId}
                            imageUrl={process.env.REACT_APP_IMAGE_BASE + reward.imageUrl}
                            title={reward.name}
                            location={reward.location}
                            history={this.props.history}/>
                    }
                )}
            </div>
        )
    }

}

export default withRouter(WalletRewardListController)