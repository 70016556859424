import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import SignInController from "./controllers/SignInController"
import IndexController from "./controllers/IndexController"
import EarnController from "./controllers/EarnController"
import SpendController from "./controllers/SpendController"
import RankController from "./controllers/RankController"
import WalletController from "./controllers/WalletController"
import QrScanController from "./controllers/QrScanController"
import CreateAccountController from "./controllers/CreateAccountController"
import AccountCreatedController from "./controllers/AccountCreatedController"
import EarnItemController from "./controllers/EarnItemController"
import RewardItemController from "./controllers/RewardItemController"
import ProfileController from "./controllers/ProfileController"
import ForgotPasswordController from "./controllers/ForgotPasswordController"
import TermsAndConditionsController from "./controllers/TermsAndConditionsController"
import VerificationEmailSentController from "./controllers/VerificationEmailSentController"
import EarnVerifiedController from "./controllers/EarnVerifiedController"
import PasswordResetController from "./controllers/PasswordResetController"
import PasswordResetEmailSentController from "./controllers/PasswordResetEmailSentController"
import PasswordResetCompleteController from "./controllers/PasswordResetCompleteController"
import PurchasedController from "./controllers/PurchasedController";
import TermsAndConditionsFullCopyController from "./controllers/TermsAndConditionsFullCopyController";
import ChooseYourTeamController from "./controllers/ChooseYourTeamController";
import CreateAccountForOrganisationController from "./controllers/CreateAccountForOrganisationController";
import FrequentlyAskedQuestionsController from "./controllers/FrequentlyAskedQuestionsController";
import HomeAltController from "./controllers/HomeAltController";
import CreateAccountForOrganisationAltController from "./controllers/CreateAccountForOrganisationAltController";
import CreateAccountForDemoController from "./controllers/CreateAccountForDemoController";
import Supply from "./dash/Supply";
import {EthereumController} from "./eth/EthereumController";
import BudgetController from "./controllers/BudgetController";


function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/old" exact component={IndexController}/>
                <Route path="/" exact component={HomeAltController}/>
                <Route path="/create-account" component={CreateAccountController}/>
                <Route path="/create-account-demo" component={CreateAccountForDemoController}/>
                <Route path="/create-account-amsterdam.nl" render={(props) =>
                    <CreateAccountForOrganisationController
                        {...props}
                        introText={"Welcome Amsterdam!"}
                        emailDomain={"amsterdam.nl"}
                    />
                }/>
                <Route path="/accept-tac" component={TermsAndConditionsController}/>
                <Route path="/terms-and-conditions" component={TermsAndConditionsFullCopyController}/>
                <Route path="/thanks-for-signing-up" component={VerificationEmailSentController}/>
                <Route path="/activate" component={AccountCreatedController}/>
                <Route path="/activate-alt" component={CreateAccountForOrganisationAltController}/>
                <Route path="/sign-in" component={SignInController}/>
                <Route path="/forgot-password" component={ForgotPasswordController}/>
                <Route path="/password-reset-email-sent" component={PasswordResetEmailSentController}/>
                <Route path="/password/reset" component={PasswordResetController}/>
                <Route path="/password-reset-complete" component={PasswordResetCompleteController}/>
                <Route path="/earn/:id" component={EarnItemController}/>
                <Route path="/earn" component={EarnController}/>
                <Route path="/spend/:id" component={RewardItemController}/>
                <Route path="/spend" component={SpendController}/>
                <Route path="/rank" component={RankController}/>
                <Route path="/wallet" component={WalletController}/>
                <Route path="/faq" component={FrequentlyAskedQuestionsController}/>
                <Route path="/qrscan" component={QrScanController}/>
                <Route path="/purchased/:id" component={PurchasedController}/>
                <Route path="/earn-verified" component={EarnVerifiedController}/>
                <Route path="/profile" component={ProfileController}/>
                <Route path="/choose-team" component={ChooseYourTeamController}/>

                <Route path="/supply" component={Supply}/>
                <Route path="/budget" component={BudgetController}/>
                <Route path="/ethwallet" component={EthereumController}/>
            </Switch>
        </BrowserRouter>
    )
}

export default App
