/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class TermsAndConditionsFormView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/TermsAndConditionsFormController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = TermsAndConditionsFormView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== TermsAndConditionsFormView ? transformProxies(this.props.children) : {
      'i18n-tac-summary': [],
      'i18n-point-1': [],
      'i18n-point-2': [],
      'i18n-point-3': [],
      'full-tac': [],
      'confirm-check': [],
      'i18n-accept-tac': [],
      'submit': [],
      'submit-error': [],
      'submit-email-error': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="w-form">
            <form id="email-form" name="email-form" data-name="Email Form">
              <p className="af-class-paragraph-7">{map(proxies['i18n-tac-summary'], props => <span {...{...props, className: `af-class-terms-text ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Here is a summary of the way we use any data collected. We try to be as clear as possible so you know exactly how things work. If you have any questions reach us at hello@ecocoin.com. <br /></React.Fragment>}</span>)}</p>
              <p className="af-class-paragraph-8">{map(proxies['i18n-point-1'], props => <span {...{...props, className: `af-class-terms-text ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>1. Your data is never shared with a third party.<br /></React.Fragment>}</span>)}</p>
              <p className="af-class-terms-text">{map(proxies['i18n-point-2'], props => <span {...props}>{props.children ? props.children : <React.Fragment>2. You have the right to stop and delete your account at any time.<br /></React.Fragment>}</span>)}</p>
              <p className="af-class-terms-text">{map(proxies['i18n-point-3'], props => <span {...props}>{props.children ? props.children : <React.Fragment>3. We reserve the right to shut down accounts that we believe are fraudulent or not adhering to the platforms guidelines expressed in the terms and conditions.<br /></React.Fragment>}</span>)}</p>{map(proxies['full-tac'], props => <a href="#" {...{...props, className: `af-class-link-green-underline ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Please read the full terms and conditions are here.<br /></React.Fragment>}</a>)}
              <div className="af-class-tac-space" /><label className="w-checkbox af-class-checkbox-field">{map(proxies['confirm-check'], props => <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" required {...{...props, className: `w-checkbox-input af-class-checkbox ${props.className || ''}`}}>{props.children}</input>)}{map(proxies['i18n-accept-tac'], props => <span {...{...props, className: `af-class-checkbox-label w-form-label ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>I have read the terms and conditions</React.Fragment>}</span>)}</label>{map(proxies['submit'], props => <input type="submit" value="Submit" data-wait="Please wait..." {...{...props, className: `af-class-button w-button ${props.className || ''}`}}>{props.children}</input>)}
              {map(proxies['submit-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Error with registration. Try again later.</React.Fragment>}</p>)}
              {map(proxies['submit-email-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Email address already registered.</React.Fragment>}</p>)}
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </span>
      </span>
    )
  }
}

export default TermsAndConditionsFormView

/* eslint-enable */