import React from 'react'
import WalletView from "../views/WalletView"
import {authenticationService} from '../services/authentication.service'
import {walletService} from "../services/wallet.service"
import {withRouter} from 'react-router'
import translate from "../i18n/translate";
import EcoValue from "../components/EcoValue";


class WalletController extends React.Component {
    state = {
        user: {
            firstName: '',
            lastName: ''
        },
        wallet: {
            balance: 0,
            co2Saved: 0
        }

    }

    componentWillMount() {
        const currentUser = authenticationService.currentUserValue
        this.setState({user: currentUser})
        walletService.overview().then((wallet) => {
            this.setState({wallet: wallet})
        })
    }

    render() {
        let impactCard =
            <impact-card>
                <i18n-your-impact>{translate('wallet_yourImpact')}</i18n-your-impact>
                <i18n-you-have-saved>{translate('wallet_youHaveSaved')}</i18n-you-have-saved>
                <co2-saved><strong>{(this.state.wallet.co2Saved/1000)}kg</strong></co2-saved>
                <i18n-of-co2e-this-week>{translate('wallet_ofCo2eThisWeek')}</i18n-of-co2e-this-week>
            </impact-card>
        if(authenticationService.isTestVariation('A')) {
            impactCard = ''
        }

        return (
            <WalletView>
                <profile-link onClick={this.gotoProfile}/>
                <support-link onClick={this.gotoFAQ}/>
                <i18n-hello>{translate('wallet_hello')}</i18n-hello>
                <username>{this.state.user.nickname}</username>
                <i18n-your-current-balance-is>{translate('wallet_yourCurrentBalanceIs')}</i18n-your-current-balance-is>
                <balance><EcoValue value={this.state.wallet.balance}/></balance>
                { impactCard }
                <i18n-recent-activities>{translate('wallet_recentActivities')}</i18n-recent-activities>
                <i18n-your-rewards>{translate('wallet_yourRewards')}</i18n-your-rewards>
                <i18n-click-on-your-purchased-rewards>{translate('wallet_clickOnYourPurchasedRewards')}</i18n-click-on-your-purchased-rewards>
            </WalletView>
        )
    }

    gotoProfile = (e) => {
        this.props.history.push('/profile')
        e.preventDefault()
    }

    gotoFAQ = (e) => {
        this.props.history.push('/faq')
        e.preventDefault()
    }
}

export default withRouter(WalletController)