/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import ProfileInfoFormView from './ProfileInfoFormView'
import ProfilePasswordFormView from './ProfilePasswordFormView'
import BottomNavbarView from './BottomNavbarView'

const scripts = [
  fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js").then(body => body.text()),
  fetch("js/webflow.js").then(body => body.text()),
]

let Controller

class ProfileView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/ProfileController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = ProfileView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== ProfileView ? transformProxies(this.props.children) : {
      'i18n-my-profile': [],
      'i18n-name': [],
      'name': [],
      'i18n-email': [],
      'email': [],
      'i18n-update-password': [],
      'logout': [],
      'delete-account': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body-white-background">
            <div className="af-class-centered-section-too-long">
              <div className="af-class-my-profile-container w-container">
                {map(proxies['i18n-my-profile'], props => <h1 {...{...props, className: `af-class-title ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>My Profile</React.Fragment>}</h1>)}
                <h5 className="af-class-profile-info">{map(proxies['i18n-name'], props => <span {...props}>{props.children ? props.children : <React.Fragment>Name</React.Fragment>}</span>)}: {map(proxies['name'], props => <span {...{...props, className: `af-class-text-span ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Lewis Just</React.Fragment>}</span>)}</h5>
                <h5 className="af-class-profile-info">{map(proxies['i18n-email'], props => <span {...props}>{props.children ? props.children : <React.Fragment>Email</React.Fragment>}</span>)}: {map(proxies['email'], props => <span {...{...props, className: `af-class-text-span-2 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>lewismjust@gmail.com</React.Fragment>}</span>)}</h5>
                <ProfileInfoFormView.Controller />
                {map(proxies['i18n-update-password'], props => <h5 {...{...props, className: `af-class-profile-info ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Update Password</React.Fragment>}</h5>)}
                <ProfilePasswordFormView.Controller />
                <p className="af-class-text-bellow-button">{map(proxies['logout'], props => <a href="#" {...{...props, className: `af-class-log-out ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Log out</React.Fragment>}</a>)}</p>
                <p className="af-class-text-bellow-button">{map(proxies['delete-account'], props => <a href="mailto:hello@ecocoin.com?subject=Please%20delete%20my%20account" {...{...props, className: `af-class-delete-account ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>delete account</React.Fragment>}</a>)}</p>
              </div>
            </div>
            <BottomNavbarView.Controller />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default ProfileView

/* eslint-enable */