import React from 'react'
import {withRouter} from 'react-router'
import translate from "../i18n/translate";
import HomeAltView from "../views/HomeAltView";


class HomeAltController extends React.Component {
    state = {};

    render() {
        return (
            <HomeAltView>
                <i18n-already-have-an-account>{translate('index_alreadyHaveAnAccount')}</i18n-already-have-an-account>
                <sign-in onClick={this.onSignIn}>{translate('index_signIn')}</sign-in>
            </HomeAltView>
        )
    }

    onSignIn = (e) => {
        this.props.history.push('/sign-in')
        e.preventDefault()
    }
}

export default withRouter(HomeAltController)