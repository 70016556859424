import React from 'react'
import {drizzleConnect} from "@drizzle/react-plugin"
import PropTypes from "prop-types";
import {ethereumService} from "../services/ethereum.service";
import BN from "bn.js";


class EthWalletDepositForm extends React.Component {

    state = {
        amount: '',
        depositTx: null,
        depositAmount: 0,
        depositStatus: 'none'
    }

    constructor(props, context) {
        super(props)
        console.log("props", props)
        console.log("context", context)

        this.contracts = context.drizzle.contracts
        this.EcoCoin = context.drizzle.contracts.EcoCoin
        console.log("contracts", this.contracts)
        this.utils = context.drizzle.web3.utils
        console.log("utils", this.utils)

        this.store = context.drizzle.store
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.depositTx !== null) {
            const txState = this.getTxState(this.store.getState(), this.state.depositTx)
            if (this.state.depositStatus === 'approve_pending' && txState.status === 'success') {
                this.setState({
                    depositStatus: 'deposit_initiated'
                })
                ethereumService
                    .deposit(this.props.accounts[0], this.state.depositAmount * process.env.REACT_APP_ECOCOIN_DIVIDER)
                    .then(response => {
                        this.setState({
                            depositStatus: 'deposit_submitted'
                        })
                    })
            }
            console.log("txState", this.state.depositStatus, txState)
        }
    }

    getTxState(storeState, txId) {
        const txHash = storeState.transactionStack[txId]
        if (!txHash.startsWith("0x")) {
            return {
                txHash: 'none',
                status: 'none'
            }
        }
        const status = storeState.transactions[txHash].status
        return {
            txHash: txHash,
            status: status
        }
    }

    render() {

        let statusTag = <span/>
        if (this.state.depositTx !== null) {
            const state = this.store.getState()
            const txHash = state.transactionStack[this.state.depositTx]
            console.log("txHash", txHash)
            if (txHash.startsWith("0x")) {
                const status = state.transactions[txHash].status
                statusTag =
                    <div>
                        <div>status: {status}</div>
                    </div>
            }
        }

        return (
            <div className="w-form">
                <form>
                    <label
                        className="af-class-profile-info"
                        htmlFor="deposit-amount"
                    >
                        Deposit to App Wallet
                    </label>
                    <input
                        id="deposit-amount"
                        className="af-class-textfield w-input"
                        placeholder="ECO amount"
                        type="text"
                        value={this.state.amount}
                        onChange={this.setAmount}
                    />
                    <input
                        className="af-class-button w-button"
                        value="Deposit"
                        type="submit"
                        onClick={this.submit}
                    />

                </form>
                {statusTag}
            </div>
        )
    }

    setAmount = (e) => {
        this.setState({
            amount: e.target.value
        })
    }

    submit = (e) => {
        console.log("Submit", this.props.contract)
        const input = new BN(this.state.amount)
        const f = new BN(process.env.REACT_APP_ECOCOIN_DIVIDER)
        const amount = input.mul(f)
        console.log("Amount:", input.toString(), f.toString(), amount.toString())
        let tx = this.EcoCoin.methods["approve"].cacheSend(
            this.props.appaccount, amount.toString()
        )
        this.setState({
            depositTx: tx,
            depositAmount: this.state.amount,
            depositStatus: 'approve_pending'
        })
        e.preventDefault()
    }

}


EthWalletDepositForm.contextTypes = {
    drizzle: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        contracts: state.contracts,
        accounts: state.accounts
    };
};

export default drizzleConnect(EthWalletDepositForm, mapStateToProps)
