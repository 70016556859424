/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import BottomNavbarView from './BottomNavbarView'

const scripts = [
  fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js").then(body => body.text()),
  fetch("js/webflow.js").then(body => body.text()),
]

let Controller

class RewardItemView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/RewardItemController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = RewardItemView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== RewardItemView ? transformProxies(this.props.children) : {
      'product-image': [],
      'back': [],
      'value': [],
      'title': [],
      'location': [],
      'availability': [],
      'description': [],
      'buy': [],
      'not-enough-coins-error': [],
      'out-of-stock-error': [],
      'user-limit-reached-error': [],
      'too-many-transactions-error': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body-for-earn-card">
            <div className="af-class-reward-card-header-image-copy">
              <div className="af-class-div-block-23 w-clearfix">
                {map(proxies['product-image'], props => <div {...{...props, className: `af-class-reward-image-box ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/bethemessenger_drink_fron_dopper.jpg1BB9AD289BBCC067E7C4B9CE9BE0FCF6.jpg" width={100} alt="Product image" className="af-class-earn-image" /></React.Fragment>}</div>)}{map(proxies['back'], props => <a href="spend.html" {...{...props, className: `af-class-link-block-3 w-inline-block ${props.className || ''}`}}>{props.children}</a>)}
                <div className="af-class-price-circle-green">
                  {map(proxies['value'], props => <div {...{...props, className: `af-class-text-block-2 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>500E</React.Fragment>}</div>)}
                </div>
                <div className="af-class-earn-text-card w-clearfix">
                  {map(proxies['title'], props => <h4 {...{...props, className: `af-class-earn-name ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Eat a meat free meal</React.Fragment>}</h4>)}
                  {map(proxies['location'], props => <h4 {...{...props, className: `af-class-location ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Canteen</React.Fragment>}</h4>)}
                  <div className="af-class-div-block-14 w-clearfix">
                    {map(proxies['availability'], props => <h4 {...{...props, className: `af-class-stock-availability ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Few left</React.Fragment>}</h4>)}
                  </div>
                  {map(proxies['description'], props => <p {...{...props, className: `af-class-normal-text-left-justified ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>We use 26% of the energy in our homes for heating, so one of the top tips for saving CO2 is to turn your thermostat down by 1C. This would cut your heating bills by up to 10%, saving you around €40 a year, and the planet around 300kg of CO2!</React.Fragment>}</p>)}{map(proxies['buy'], props => <a href="purchased.html" {...{...props, className: `af-class-buy-now-button w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Buy Now</React.Fragment>}</a>)}
                  {map(proxies['not-enough-coins-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>You do not have enough coins</React.Fragment>}</p>)}
                  {map(proxies['out-of-stock-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Item is out of stock</React.Fragment>}</p>)}
                  {map(proxies['user-limit-reached-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>You have reached your limit of<br />buying this item</React.Fragment>}</p>)}
                  {map(proxies['too-many-transactions-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Too many transactions <br />within the last 5 minutes</React.Fragment>}</p>)}
                </div>
                <div className="af-class-div-block-24" />
              </div>
            </div>
            <BottomNavbarView.Controller />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default RewardItemView

/* eslint-enable */