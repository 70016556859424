import React from 'react'
import IndexView from "../views/IndexView";
import {withRouter} from 'react-router'
import translate from "../i18n/translate";


class IndexController extends React.Component {
    state = {};

    render() {
        return (
            <IndexView>
                <create-account onClick={this.onCreateAccount}>{translate('index_createAccount')}</create-account>
                <i18n-already-have-an-account>{translate('index_alreadyHaveAnAccount')}</i18n-already-have-an-account>
                <sign-in onClick={this.onSignIn}>{translate('index_signIn')}</sign-in>
            </IndexView>
        )
    }

    onCreateAccount = (e) => {
        if(window.location.hostname === 'demo.ecocoin.com') {
            this.props.history.push('/create-account-demo')
        } else {
            this.props.history.push('/create-account-amsterdam.nl')
        }
        e.preventDefault()
    }

    onSignIn = (e) => {
        this.props.history.push('/sign-in')
        e.preventDefault()
    }
}

export default withRouter(IndexController)