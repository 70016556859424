import React from 'react'
import {withRouter} from 'react-router'
import CreateAccountFormView from "../views/CreateAccountFormView"
import translate, {translatePlaceholder, translateValue} from "../i18n/translate"
import {EMAIL_PATTERN, PASSWORD_PATTERN} from "../helpers/patterns"


class CreateAccountFormController extends React.Component {
    state = {
        firstName: '',
        firstNameError: 'none',
        lastName: '',
        lastNameError: 'none',
        nickname: '',
        nicknameError: 'none',
        email: '',
        emailError: 'none',
        password: '',
        passwordError: 'none',
        passwordRepeat: '',
        passwordRepeatError: 'none'
    }

    render() {
        let firstNameInput =
            <first-name onChange={this.setFirstName} {...translatePlaceholder('createAccount_firstName')}/>
        let firstNameError =
            <first-name-error
                style={{display: this.state.firstNameError}}>{translate('createAccount_firstNameError')}</first-name-error>
        let lastNameInput =
            <last-name onChange={this.setLastName} {...translatePlaceholder('createAccount_lastName')}/>
        let lastNameError =
            <last-name-error
                style={{display: this.state.lastNameError}}>{translate('createAccount_lastNameError')}</last-name-error>
        console.log("PROPS ", this.props)
        if (this.props.skipRealName === true) {
            firstNameInput = <span/>
            firstNameError = <span/>
            lastNameInput = <span/>
            lastNameError = <span/>
        }
        let emailDomain = <span/>
        if (this.props.emailDomain !== undefined) {
            emailDomain = <email-domain>@{this.props.emailDomain}</email-domain>
        }
        return (
            <CreateAccountFormView>
                {firstNameInput}
                {firstNameError}
                {lastNameInput}
                {lastNameError}

                <email onChange={this.setEmail} {...translatePlaceholder('createAccount_email')}/>
                {emailDomain}
                <email-error
                    style={{display: this.state.emailError}}>{translate('createAccount_emailError')}</email-error>

                <nickname onChange={this.setNickname} {...translatePlaceholder('createAccount_nickname')}/>
                <nickname-error
                    style={{display: this.state.nicknameError}}>{translate('createAccount_nicknameError')}</nickname-error>
                <i18n-password-requirements>{translate('createAccount_passwordHint')}</i18n-password-requirements>
                <password onChange={this.setPassword} {...translatePlaceholder('createAccount_password')}/>
                <password-error
                    style={{display: this.state.passwordError}}>{translate('createAccount_passwordError')}</password-error>
                <password-repeat
                    onChange={this.setPasswordRepeat} {...translatePlaceholder('createAccount_passwordRepeat')}/>
                <password-repeat-error
                    style={{display: this.state.passwordRepeatError}}>{translate('createAccount_passwordRepeatError')}</password-repeat-error>
                <submit onClick={this.submit} {...translateValue('createAccount_createAccount')}/>
                <i18n-already-have-an-account>{translate('createAccount_alreadyHaveAnAccount')}</i18n-already-have-an-account>
                <sign-in onClick={this.onSignIn}>{translate('createAccount_signIn')}</sign-in>
            </CreateAccountFormView>
        )
    }

    setFirstName = (e) => {
        this.setState({
            firstName: e.target.value.trim()
        })
    }
    setLastName = (e) => {
        this.setState({
            lastName: e.target.value.trim()
        })
    }
    setNickname = (e) => {
        this.setState({
            nickname: e.target.value.trim()
        })
    }
    setEmail = (e) => {
        this.setState({
            email: e.target.value.trim()
        })
    }
    setPassword = (e) => {
        this.setState({
            password: e.target.value
        })
    }
    setPasswordRepeat = (e) => {
        this.setState({
            passwordRepeat: e.target.value
        })
    }
    submit = (e) => {
        this.setState({
            firstNameError: 'none',
            lastNameError: 'none',
            nicknameError: 'none',
            emailError: 'none',
            passwordError: 'none',
            passwordRepeatError: 'none'
        })
        let account = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            nickname: this.state.nickname,
            email: this.state.email,
            password: this.state.password
        }
        let error = false
        if (!this.props.skipRealName) {
            if (this.state.firstName === '') {
                this.setState({
                    firstNameError: 'block'
                })
                error = true
            }
            if (this.state.lastName === '') {
                this.setState({
                    lastNameError: 'block'
                })
                error = true
            }
        }
        if (this.state.nickname === '') {
            this.setState({
                nicknameError: 'block'
            })
            error = true
        }

        if (!EMAIL_PATTERN.test(account.email)) {
            this.setState({
                emailError: 'block'
            })
            error = true
        }
        if (!PASSWORD_PATTERN.test(this.state.password)) {
            this.setState({
                passwordError: 'block'
            })
            error = true
        }
        if (this.state.passwordRepeat === '' || !(this.state.password === this.state.passwordRepeat)) {
            this.setState({
                passwordRepeatError: 'block'
            })
            error = true
        }

        if (error === false) {
            console.log("Submit", this.state)
            this.props.history.push({
                pathname: '/accept-tac',
                state: account
            })
        }
        e.preventDefault()
    }

    onSignIn = (e) => {
        this.props.history.push('/sign-in')
        e.preventDefault()
    }
}

export default withRouter(CreateAccountFormController)
