/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js").then(body => body.text()),
  fetch("js/webflow.js").then(body => body.text()),
]

let Controller

class BudgetView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/BudgetController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = BudgetView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== BudgetView ? transformProxies(this.props.children) : {
      'carbon-budget': [],
      'time-years': [],
      'time-months': [],
      'time-days': [],
      'time-hours': [],
      'time-mins': [],
      'time-secs': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div>
            <div>
              <div className="af-class-div-block-copy"><img src="images/ECO-coin-yellow-green.svg" alt="E" className="af-class-image-copy" /><img src="images/ECO-coin-logo-green.svg" alt="THE ECO COIN" className="af-class-image-2" />
                <h1 className="af-class-tagline">Do good, get paid</h1>
              </div>
              <h1 className="af-class-title">Remaining Carbon Budget</h1>
              {map(proxies['carbon-budget'], props => <p {...{...props, className: `af-class-paragraph-12 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>229.160278</React.Fragment>}</p>)}
              <div className="af-class-text-block-8">billion tonnes CO2 left for a 66% chance to limit global warming to 1.5°C *</div>
            </div>
            <p className="af-class-paragraph-13">{map(proxies['time-years'], props => <span {...{...props, className: `af-class-time-left ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>5</React.Fragment>}</span>)} years, {map(proxies['time-months'], props => <span {...{...props, className: `af-class-time-left ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>4</React.Fragment>}</span>)} months, {map(proxies['time-days'], props => <span {...{...props, className: `af-class-time-left ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>27</React.Fragment>}</span>)} days, {map(proxies['time-hours'], props => <span {...{...props, className: `af-class-time-left ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>20</React.Fragment>}</span>)} hours, {map(proxies['time-mins'], props => <span {...{...props, className: `af-class-time-left ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>10</React.Fragment>}</span>)} mins, {map(proxies['time-secs'], props => <span {...{...props, className: `af-class-time-left ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>1</React.Fragment>}</span>)} secs</p>
            <div className="af-class-text-block-8">until budget is depleted with current rate of emissions</div>
            <div className="af-class-text-block-8"><a target="_blank" href="https://constrain-eu.org/" className="af-class-link-budget">*&nbsp;Based on the 2019 report of the CONSTRAIN project</a></div>
            <div className="af-class-div-block-358">
              <div className="af-class-div-block-43"><a href="https://www.ecocoin.com/" target="_blank" className="af-class-button-4 af-class-button-copy-dac w-button">Join US</a></div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default BudgetView

/* eslint-enable */