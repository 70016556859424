import React from 'react'
import {earnsService} from "../services/earns.service";
import {withRouter} from 'react-router'
import EarnItemView from "../views/EarnItemView";
import EarnDates from "../components/EarnDates";
import translate from "../i18n/translate";
import EarnAvailability from "../components/EarnAvailability";
import EcoValue from "../components/EcoValue";
import {authenticationService} from "../services/authentication.service";


class EarnItemController extends React.Component {
    state = {
        earn: {
            imageUrl: '',
            name: '???',
            subtitle: '???',
            location: '???',
            co2ReductionDesc: '???',
            amount: 0,
            description: '???',
            earnDates: [],
            usageRestriction: ''
        }
    };

    componentWillMount() {
        earnsService.get(this.props.match.params.id).then((earn) => {
            this.setState({earn: earn})
        })
    }

    render() {
        let impact = <co2reduction>{this.state.earn.co2ReductionDesc}</co2reduction>
        if(authenticationService.isTestVariation('A')) {
            impact = ''
        }

        return (
            <EarnItemView>
                <back onClick={this.navBack}/>
                <product-image><img src={process.env.REACT_APP_IMAGE_BASE + this.state.earn.imageUrl} alt=""/>
                </product-image>
                <title>{this.state.earn.name}</title>
                <subtitle>{this.state.earn.subtitle}</subtitle>
                <location>{this.state.earn.location}</location>
                <value><EcoValue value={this.state.earn.amount} withSymbol={true}/></value>
                { impact }
                <description><div dangerouslySetInnerHTML={{__html: this.state.earn.description}}/></description>
                <availability>
                    <EarnAvailability availability={this.state.earn.usageRestriction}/>
                </availability>
                <time-constraint>
                    <EarnDates timeConstraint={this.state.earn.earnDates}/>
                </time-constraint>
                <scan onClick={this.navScan}>{translate('earnItem_scan')}</scan>
            </EarnItemView>
        )
    }

    navScan = (e) => {
        this.props.history.push('/qrscan')
        e.preventDefault()
    }

    navBack = (e) => {
        this.props.history.goBack()
        e.preventDefault()
    }

}

export default withRouter(EarnItemController)
