import nl from './nl'

var lang = null
export var langStr = 'en'
detectLanguage()

function detectLanguage() {
    var userLang = navigator.language || navigator.userLanguage;
    if(userLang.startsWith('nl')) {
        lang = nl
        langStr = 'nl'
    }
    console.log("Language: ", langStr)
}

function translate(element) {
    if(lang === null) {
        return null
    }
    return lang[element]
}

export function translateDef(element, defaultVal) {
    if(lang === null) {
        return defaultVal
    }
    return lang[element]
}

export function translatePlaceholder(element) {
    if(lang === null) {
        return null
    }
    return {placeholder: translate(element)}
}

export function translateValue(element) {
    if(lang === null) {
        return null
    }
    return {value: translate(element)}
}

export default translate
