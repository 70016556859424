import React from 'react'
import TermsAndConditionsFullCopyView from "../views/TermsAndConditionsFullCopyView";


class TermsAndConditionsFullCopyController extends React.Component {

    render() {
        if (this.props.location.state !== undefined) {
            let {email} = this.props.location.state
            if (email && email.toLowerCase().endsWith('@amsterdam.nl')) {
                return (
                    <TermsAndConditionsFullCopyView>
                        <close onClick={this.onClose}/>
                    </TermsAndConditionsFullCopyView>
                )
            }
        }

        return (
            <TermsAndConditionsFullCopyView>
                <close onClick={this.onClose}/>
            </TermsAndConditionsFullCopyView>
        )
    }

    onClose = (e) => {
        this.props.history.goBack()
        e.preventDefault()
    }

}

export default TermsAndConditionsFullCopyController
