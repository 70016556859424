import React from 'react'
import {withRouter} from 'react-router'
import translate, {translatePlaceholder, translateValue} from "../i18n/translate";
import {PASSWORD_PATTERN} from "../helpers/patterns"
import CreateAccountForOrganisationFormView from "../views/CreateAccountForOrganisationFormView";
const querystring = require('querystring');


class CreateAccountForOrganisationAltFormController extends React.Component {

    state = {
        nickname: '',
        nicknameError: 'none',
        password: '',
        passwordError: 'none',
        passwordRepeat: '',
        passwordRepeatError: 'none'
    }

    render() {
        console.log("PROPS ", this.props)
        return (
            <CreateAccountForOrganisationFormView>
                <nickname onChange={this.setNickname} {...translatePlaceholder('createAccount_nickname')}/>
                <nickname-error
                    style={{display: this.state.nicknameError}}>{translate('createAccount_nicknameError')}</nickname-error>
                <i18n-password-requirements>{translate('createAccount_passwordHint')}</i18n-password-requirements>
                <password onChange={this.setPassword} {...translatePlaceholder('createAccount_password')}/>
                <password-error
                    style={{display: this.state.passwordError}}>{translate('createAccount_passwordError')}</password-error>
                <password-repeat
                    onChange={this.setPasswordRepeat} {...translatePlaceholder('createAccount_passwordRepeat')}/>
                <password-repeat-error
                    style={{display: this.state.passwordRepeatError}}>{translate('createAccount_passwordRepeatError')}</password-repeat-error>
                <submit onClick={this.submit} {...translateValue('createAccount_createAccount')}/>
            </CreateAccountForOrganisationFormView>
        )
    }

    setNickname = (e) => {
        this.setState({
            nickname: e.target.value.trim()
        })
    }
    setPassword = (e) => {
        this.setState({
            password: e.target.value
        })
    }
    setPasswordRepeat = (e) => {
        this.setState({
            passwordRepeat: e.target.value
        })
    }
    submit = (e) => {
        this.setState({
            nicknameError: 'none',
            passwordError: 'none',
            passwordRepeatError: 'none'
        })
        let account = {
            nickname: this.state.nickname,
            password: this.state.password,
            key: querystring.parse(this.props.location.search.substring(1)).key
        }
        let error = false
        if (this.state.nickname === '') {
            this.setState({
                nicknameError: 'block'
            })
            error = true
        }
        if (!PASSWORD_PATTERN.test(this.state.password)) {
            this.setState({
                passwordError: 'block'
            })
            error = true
        }
        if (this.state.passwordRepeat === '' || !(this.state.password === this.state.passwordRepeat)) {
            this.setState({
                passwordRepeatError: 'block'
            })
            error = true
        }

        if (error === false) {
            console.log("Submit", this.state)
            this.props.history.push({
                pathname: '/accept-tac',
                state: account
            })
        }
        e.preventDefault()
    }

}

export default withRouter(CreateAccountForOrganisationAltFormController)
