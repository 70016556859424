import React from 'react'
import {withRouter} from 'react-router'
import ChooseYourTeamFormView from "../views/ChooseYourTeamFormView"
import translate, {translateDef, translateValue} from "../i18n/translate"
import {authenticationService} from "../services/authentication.service"


class ChooseYourTeamFormController extends React.Component {

    state = {
        teams: [],
        selectedTeam: 0,
        selectTeamError: 'none',
        joinError: 'none'
    }

    componentWillMount() {
        authenticationService.getTeams()
            .then(teams => {
                if(teams.length === 0) {
                    this.props.history.push('/earn')
                } else {
                    this.setState({teams: teams})
                }
            })
    }

    render() {
        return (
            <ChooseYourTeamFormView>
                <team onChange={this.setTeam}>
                    <option key="0" value="0">{translateDef('chooseYourTeam_imOnTeam', "I'm on team ...")}</option>
                    {this.state.teams.map((team) =>
                            <option key={team.teamId} value={team.teamId}>{team.name}</option>
                    )}
                </team>
                <select-team-error style={{display: this.state.selectTeamError}}>{translate('chooseYourTeam_selectTeamError')}</select-team-error>
                <join-team onClick={this.join} {...translateValue('chooseYourTeam_joinTeam')}/>
                <join-error style={{display: this.state.joinError}}>{translate('chooseYourTeam_joinError')}</join-error>
            </ChooseYourTeamFormView>
        )
    }

    setTeam = (e) => {
        this.setState({
            selectedTeam: parseInt(e.target.value)
        })
        console.log("Select team", e.target.value);
    }

    join = (e) => {
        this.setState({
            selectTeamError: 'none',
            joinError: 'none'
        })

        if(this.state.selectedTeam === 0) {
            this.setState({
                selectTeamError: 'block'
            })

        } else {
            authenticationService.updateTeam(this.state.selectedTeam)
                .then(() => {
                    this.props.history.push('/earn')
                })
                .catch(error => {
                    this.setState({
                        joinError: 'block'
                    })
                })
        }

        e.preventDefault()
    }
}

export default withRouter(ChooseYourTeamFormController)
