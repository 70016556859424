import React from 'react'
import {spendsService} from "../services/spends.service";
import {withRouter} from 'react-router'
import RewardItemView from "../views/RewardItemView";
import translate, {translateDef} from "../i18n/translate";
import EcoValue from "../components/EcoValue";


class SpendItemController extends React.Component {
    state = {
        spend: {
            imageUrl: '',
            name: '???',
            location: '???',
            availability: '???',
            amount: 0,
            description: '???'
        },
        notEnoughCoinsError: 'none',
        outOfStockError: 'none',
        userLimitReachedError: 'none',
        tooManyTransactionsError: 'none'
    }

    componentWillMount() {
        spendsService.get(this.props.match.params.id).then((spend) => {
            this.setState({spend: spend})
        })
    }

    render() {
        let availabilityText = this.state.spend.usage === 100
            ? translateDef('rewardItem_soldOut', 'Sold out')
            : this.state.spend.usage >= 75
                ? translate('rewardItem_fewLeft', 'Few left')
                : null;

        return (
            <RewardItemView>
                <back onClick={this.navBack}/>
                <buy onClick={this.buyItem}>
                    {translate('rewardItem_buy')}
                </buy>
                <product-image>
                    <img src={process.env.REACT_APP_IMAGE_BASE + this.state.spend.imageUrl} alt=""/>
                </product-image>
                <title>{this.state.spend.name}</title>
                <location>{this.state.spend.location}</location>
                <value>
                    <EcoValue value={this.state.spend.amount} withSymbol={true}/>
                </value>
                <description>
                    <div dangerouslySetInnerHTML={{__html: this.state.spend.description}}></div>
                </description>
                {this.state.spend.usage >= 75 ? <availability>{availabilityText}</availability> : <span/>}
                <not-enough-coins-error style={{display: this.state.notEnoughCoinsError}}>
                    {translate('rewardItem_notEnoughCoinsError')}
                </not-enough-coins-error>
                <out-of-stock-error style={{display: this.state.outOfStockError}}>
                    {translate('rewardItem_outOfStockError')}
                </out-of-stock-error>
                <user-limit-reached-error style={{display: this.state.userLimitReachedError}}>
                    {translate('rewardItem_userLimitReachedError')}
                </user-limit-reached-error>
                <too-many-transactions-error style={{display: this.state.tooManyTransactionsError}}>
                    {translate('rewardItem_tooManyTransactionsError')}
                </too-many-transactions-error>
            </RewardItemView>)
    }

    buyItem = (e) => {
        this.setState({
            notEnoughCoinsError: 'none',
            outOfStockError: 'none',
            userLimitReachedError: 'none',
            tooManyTransactionsError: 'none'
        })

        spendsService.claim(this.state.spend.spendId)
            .then(value => {
                console.log("OK:", value)
                this.props.history.push(`/purchased/${value.referenceId}`)
            })
            .catch(reason => {
                console.log("ERR:", reason)
                switch (reason.code) {
                    case 1205:
                        this.setState({
                            notEnoughCoinsError: 'block'
                        })
                        break
                    case 1102:
                        this.setState({
                            outOfStockError: 'block'
                        })
                        break
                    case 1103:
                        this.setState({
                            userLimitReachedError: 'block'
                        })
                        break
                    case 1200:
                        this.setState({
                            tooManyTransactionsLimit: 'block'
                        })
                        break
                    default:
                        break
                }
            })

        e.preventDefault()
    }

    navBack = (e) => {
        this.props.history.goBack()
        e.preventDefault()
    }

}

export default withRouter(SpendItemController)
