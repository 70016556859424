/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class PasswordResetFormView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/PasswordResetFormController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = PasswordResetFormView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== PasswordResetFormView ? transformProxies(this.props.children) : {
      'password': [],
      'password-error': [],
      'password-repeat': [],
      'password-repeat-error': [],
      'submit': [],
      'link-expired-error': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="w-form">
            <form id="email-form" name="email-form" data-name="Email Form" className="af-class-form">{map(proxies['password'], props => <input type="password" autoComplete="new-password" maxLength={256} name="New-password-one" data-name="New password one" placeholder="New password" id="New-password-one" required {...{...props, className: `af-class-textfield w-input ${props.className || ''}`}}>{props.children}</input>)}
              {map(proxies['password-error'], props => <p {...{...props, className: `af-class-error-msg ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Password must be at least 8<br />characters long, with lower and <br />uppercase and numbers.</React.Fragment>}</p>)}{map(proxies['password-repeat'], props => <input type="password" autoComplete="new-password" maxLength={256} name="New-Password-two" data-name="New Password two" placeholder="Repeat new password" id="New-Password-two" required {...{...props, className: `af-class-textfield w-input ${props.className || ''}`}}>{props.children}</input>)}
              {map(proxies['password-repeat-error'], props => <p {...{...props, className: `af-class-error-msg ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Password does not match</React.Fragment>}</p>)}{map(proxies['submit'], props => <input type="submit" value="Reset Password" data-wait="Please wait..." {...{...props, className: `af-class-button w-button ${props.className || ''}`}}>{props.children}</input>)}
              {map(proxies['link-expired-error'], props => <p {...{...props, className: `af-class-error-msg ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Password reset link has expired</React.Fragment>}</p>)}
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </span>
      </span>
    )
  }
}

export default PasswordResetFormView

/* eslint-enable */