/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class SpendCardView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/SpendCardController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = SpendCardView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== SpendCardView ? transformProxies(this.props.children) : {
      'click-area': [],
      'availability': [],
      'product-image': [],
      'price': [],
      'title': [],
      'location': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-container-6 w-container">
            {map(proxies['click-area'], props => <div {...{...props, className: `af-class-spend-card w-clearfix ${props.className || ''}`}}>{createScope(props.children, proxies => <React.Fragment>
              {map(proxies['availability'], props => <h4 {...{...props, className: `af-class-stock-availability-on-marketplace-page ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Few left</React.Fragment>}</h4>)}
              {map(proxies['product-image'], props => <div {...{...props, className: `af-class-reward-image ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/Dopper.png" width={100} alt className="af-class-reward-picture" /></React.Fragment>}</div>)}
              <div className="af-class-price-circle-marketplace">
                {map(proxies['price'], props => <div {...{...props, className: `af-class-marketplace-eco-number ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>50E</React.Fragment>}</div>)}
              </div>
              <div className="af-class-earn-text">
                {map(proxies['title'], props => <h4 {...{...props, className: `af-class-reward-name ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Dopper Bottle</React.Fragment>}</h4>)}
                {map(proxies['location'], props => <h4 {...{...props, className: `af-class-reward-location ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Canteen</React.Fragment>}</h4>)}
              </div>
            </React.Fragment>)}</div>)}
          </div>
        </span>
      </span>
    )
  }
}

export default SpendCardView

/* eslint-enable */