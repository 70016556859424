import {BehaviorSubject} from 'rxjs'
import {authHeader, authHeaderWithToken, handleResponse, plainHeader} from '../helpers'
const querystring = require('querystring');


const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')))

export const authenticationService = {
    login,
    logout,
    createAccount,
    createAccountAlt,
    createAccountAltFinish,
    activate,
    updateUserInfo,
    updateNickname,
    updatePassword,
    sendPasswordResetMail,
    resetPassword,
    getTeams,
    updateTeam,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    },
    isTestVariation,
    getClientInfo
}

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: plainHeader(),
        body: JSON.stringify({username, password})
    }

    return fetch(process.env.REACT_APP_USERS_AUTHENTICATE, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            setLoginData(user)
            return user
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser')
    currentUserSubject.next(null)
}

function createAccount(firstName, lastName, nickname, username, password, clientId) {
    const requestOptions = {
        method: 'POST',
        headers: plainHeader(),
        body: JSON.stringify({firstName, lastName, nickname, username, password, clientId})
    }
    return fetch(process.env.REACT_APP_USERS_CREATE, requestOptions)
        .then(handleResponse)
        .then(response => {
            // On SOI flow, we get login data and token here
            setLoginData(response)
            return response
        })
}

function createAccountAlt(email) {
    const requestOptions = {
        method: 'POST',
        headers: plainHeader(),
        body: JSON.stringify({username: email})
    }
    return fetch(process.env.REACT_APP_USERS_CREATE, requestOptions)
        .then(handleResponse)
        .then(response => {
            console.log("Create account response", response)
            return response
        })
}

function createAccountAltFinish(nickname, password, singleUseToken) {
    const requestOptions = {
        method: 'POST',
        headers: plainHeader(),
        body: JSON.stringify({nickname, password, singleUseToken})
    }
    return fetch(process.env.REACT_APP_USERS_CREATE_FINISH, requestOptions)
        .then(handleResponse)
        .then(response => {
            // We get login data and token here
            setLoginData(response)
            return response
        })
}

function activate(key) {
    const requestOptions = {
        method: 'PUT',
        headers: plainHeader()
    }
    console.log("ACTIVATE", querystring.stringify({key: key}))
    return fetch(process.env.REACT_APP_USERS_ACTIVATE + '?' +
        querystring.stringify({key: key}), requestOptions)
        .then(handleResponse)
        .then(response => {
            console.log("Account activated", response)
            return response
        })
}

function updateNickname(username) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({username})
    }
    return fetch(process.env.REACT_APP_USERS_UPDATE_NICKNAME, requestOptions)
        .then(handleResponse)
        .then(response => {
            console.log("Nickname update success")
            let user = currentUserSubject.value
            user.nickname = username
            setCurrentUser(user)
            return response
        })
}


function updateUserInfo(firstName, lastName) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({firstName, lastName})
    }
    return fetch(process.env.REACT_APP_USERS_UPDATEINFO, requestOptions)
        .then(handleResponse)
        .then(response => {
            console.log("Account update success")
            let user = currentUserSubject.value
            user.firstName = firstName
            user.lastName = lastName
            setCurrentUser(user)
            return response
        })
}

function updatePassword(password, passwordNew) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({oldPassword: password, newPassword: passwordNew})
    }
    return fetch(process.env.REACT_APP_USERS_UPDATEPASSWORD, requestOptions)
        .then(handleResponse)
        .then(response => {
            console.log("Password update success")
            return response
        })
}

function sendPasswordResetMail(username) {
    const requestOptions = {
        method: 'POST',
        headers: plainHeader(),
        body: JSON.stringify({username: username})
    }
    return fetch(process.env.REACT_APP_USERS_SEND_PASSWORD_RESET, requestOptions)
        .then(handleResponse)
        .then(response => {
            console.log("Send password mail success")
            return response
        })
}

function resetPassword(key, password) {
    const requestOptions = {
        method: 'PUT',
        headers: plainHeader(),
        body: JSON.stringify({password: password})
    }
    return fetch(process.env.REACT_APP_USERS_RESET_PASSWORD + key, requestOptions)
        .then(handleResponse)
        .then(response => {
            console.log("Reset password success")
            return response
        })
}

function getTeams() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    return fetch(process.env.REACT_APP_USERS_GET_TEAMS, requestOptions)
        .then(handleResponse)
        .then(response => {
            console.log('Read teams:', response)
            return response
        })
}

function updateTeam(teamId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify({teamId: teamId})
    }
    return fetch(process.env.REACT_APP_USERS_UPDATE_TEAM, requestOptions)
        .then(handleResponse)
}

function isTestVariation(test) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }
    fetch(process.env.REACT_APP_USERS_GETINFO, requestOptions)
        .then(handleResponse)
        .then(user => {
            console.log('Read user info:', user)
            currentUserSubject.next({
                ...currentUserSubject.value,
                testVariation: user.testVariation
            })
        })

    return currentUserSubject.value.testVariation === test
}

function getClientInfo(token) {
    const requestOptions = {
        method: 'GET',
        headers: authHeaderWithToken(token)
    }
    return fetch(process.env.REACT_APP_USERS_GET_CLIENTINFO, requestOptions)
        .then(handleResponse)
}

// Private
function setCurrentUser(user) {
    localStorage.setItem('currentUser', JSON.stringify(user))
}

function setLoginData(user) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    setCurrentUser(user)
    currentUserSubject.next(user)
}
