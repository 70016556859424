/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import EarnSearchFormView from './EarnSearchFormView'
import EarnCardListView from './EarnCardListView'
import BottomNavbarView from './BottomNavbarView'

const scripts = [
  fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js").then(body => body.text()),
  fetch("js/webflow.js").then(body => body.text()),
]

let Controller

class EarnView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/EarnController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = EarnView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== EarnView ? transformProxies(this.props.children) : {
      'i18n-earn-eco-coins': [],
      'i18n-have-a-new-idea': [],
      'i18n-let-us-know': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-earn-title-section">
              <div className="af-class-header-container w-container">
                {map(proxies['i18n-earn-eco-coins'], props => <h1 {...{...props, className: `af-class-title ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Earn ECO coins</React.Fragment>}</h1>)}
              </div>
            </div>
            <div className="af-class-section-3">
              <div className="af-class-div-block-3">
                <EarnSearchFormView.Controller />
              </div>
            </div>
            <div className="af-class-section-4-copy">
              <div className="af-class-container-6 w-container">
                <EarnCardListView.Controller />
              </div>
              <div className="af-class-div-block-13">
                {map(proxies['i18n-have-a-new-idea'], props => <h4 {...{...props, className: `af-class-more-ways-to-earn-text ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Have a new idea to earn ECOs?<br /></React.Fragment>}</h4>)}{map(proxies['i18n-let-us-know'], props => <a href="mailto:hello@ecocoin.com" {...{...props, className: `af-class-link-green-underline-for-earn ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Let us know how.</React.Fragment>}</a>)}</div>
            </div>
            <BottomNavbarView.Controller />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default EarnView

/* eslint-enable */