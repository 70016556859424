import React from 'react'
import {withRouter} from 'react-router'
import {authenticationService} from '../services/authentication.service'
import ProfilePasswordFormView from "../views/ProfilePasswordFormView"
import translate, {translatePlaceholder, translateValue} from "../i18n/translate"
import {PASSWORD_PATTERN} from "../helpers/patterns"


class ProfilePasswordFormController extends React.Component {
    state = {
        password: '',
        passwordError: 'none',
        passwordNew: '',
        passwordNewError: 'none',
        passwordRepeat: '',
        passwordRepeatError: 'none',
        success: 'none'
    }


    render() {
        return (
            <ProfilePasswordFormView>
                <password onChange={this.setPassword} {...translatePlaceholder('profile_currentPassword')}/>
                <password-error style={{display: this.state.passwordError}}>{translate('profile_passwordError')}</password-error>
                <password-new onChange={this.setPasswordNew} {...translatePlaceholder('profile_newPassword')}/>
                <password-new-error style={{display: this.state.passwordNewError}}>{translate('profile_passwordNewError')}</password-new-error>
                <password-repeat onChange={this.setPasswordRepeat} {...translatePlaceholder('profile_repeatNewPassword')}/>
                <password-repeat-error style={{display: this.state.passwordRepeatError}}>{translate('profile_passwordRepeatError')}</password-repeat-error>
                <submit onClick={this.submit} {...translateValue('profile_updatePassword')}/>
                <submit-success style={{display: this.state.success}}>{translate('profile_success')}</submit-success>
            </ProfilePasswordFormView>
        )
    }

    setPassword = (e) => {
        this.setState({
            password: e.target.value
        })
    }
    setPasswordNew = (e) => {
        this.setState({
            passwordNew: e.target.value
        })
    }
    setPasswordRepeat = (e) => {
        this.setState({
            passwordRepeat: e.target.value
        })
    }
    submit = (e) => {
        this.setState({
            passwordError: 'none',
            passwordNewError: 'none',
            passwordRepeatError: 'none',
            success: 'none'
        })
        let error = false
        if (!PASSWORD_PATTERN.test(this.state.password)) {
            this.setState({
                passwordError: 'block'
            })
            error = true
        }
        if (!PASSWORD_PATTERN.test(this.state.passwordNew)) {
            this.setState({
                passwordNewError: 'block'
            })
            error = true
        }
        if (this.state.passwordRepeat === '' || !(this.state.passwordNew === this.state.passwordRepeat)) {
            this.setState({
                passwordRepeatError: 'block'
            })
            error = true
        }

        if (error === false) {
            console.log("Submit", this.state)
            authenticationService.updatePassword(
                this.state.password,
                this.state.passwordNew
            ).then(response => {
                this.setState({
                    success: 'block'
                })
            })
        }
        e.preventDefault()
    }

}

export default withRouter(ProfilePasswordFormController)
