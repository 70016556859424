import React from 'react'
import translate from "../i18n/translate";
import CreateAccountForOrganisationAltView from "../views/CreateAccountForOrganisationAltView";
import {authenticationService} from "../services/authentication.service";

const querystring = require('querystring');


class CreateAccountForOrganisationAltController extends React.Component {

    state = {
        activateResult: {
            introText: 'Welcome'
        }
    }

    componentDidMount() {
        console.log("ACTIVATING EMAIL ", this.props.location.search)
        let key = querystring.parse(this.props.location.search.substring(1)).key
        console.log("KEY=", key)
        authenticationService.activate(key)
            .then(activateResult => {
                this.setState({
                    activateResult
                })
            })
    }

    render() {
        return (
            <CreateAccountForOrganisationAltView>
                <intro-text>
                    <div dangerouslySetInnerHTML={{__html: this.state.activateResult.introText}}/>
                </intro-text>
                <i18n-create-account>
                    {translate('createAccount_createAccount')}
                </i18n-create-account>
            </CreateAccountForOrganisationAltView>
        )
    }

}

export default CreateAccountForOrganisationAltController