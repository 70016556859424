import {authenticationService} from '../services/authentication.service'
import {langStr} from "../i18n/translate";

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
        return {
            ...plainHeader(),
            Authorization: `Bearer ${currentUser.token}`
        }
    } else {
        return plainHeader();
    }
}

export function authHeaderWithToken(token) {
    return {
        ...plainHeader(),
        Authorization: `Bearer ${token}`
    }
}

export function plainHeader() {
    return {
        'Content-Type': 'application/json',
        'Accept-Language': langStr
    }
}
