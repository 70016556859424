import React from 'react'
import {translateDef} from "../i18n/translate";

const values = {
    none: translateDef('availability_none', ''),
    twicePerDay: translateDef('availability_twicePerDay', 'Twice per day'),
    daily: translateDef('availability_daily', 'Daily'),
    weekly: translateDef('availability_weekly', 'Weekly'),
    once: translateDef('availability_once', 'Once')
}

function EarnAvailability(props) {
    const {availability} = props
    return (
        <span>{values[availability]}</span>
    )
}

export default EarnAvailability
