import React from 'react'
import FrequentlyAskedQuestionsView from "../views/FrequentlyAskedQuestionsView";


class FrequentlyAskedQuestionsController extends React.Component {
    state = {}

    render() {
        return (
            <FrequentlyAskedQuestionsView>
                <i18n-got-questions></i18n-got-questions>
                <i18n-have-a-question-we-havent></i18n-have-a-question-we-havent>
                <i18n-y-q1></i18n-y-q1>
                <i18n-a1></i18n-a1>
                <i18n-q2></i18n-q2>
                <i18n-a2></i18n-a2>
                <i18n-q3></i18n-q3>
                <i18n-a3></i18n-a3>
                <i18n-q4></i18n-q4>
                <i18n-a4></i18n-a4>
                <i18n-q5></i18n-q5>
                <i18n-a5></i18n-a5>
                <i18n-q6></i18n-q6>
                <i18n-a6></i18n-a6>
                <i18n-q7></i18n-q7>
                <i18n-a7></i18n-a7>
                <i18n-q8></i18n-q8>
                <i18n-a8></i18n-a8>
            </FrequentlyAskedQuestionsView>
        )
    }

}

export default FrequentlyAskedQuestionsController
