import React from 'react'
import {ranksService} from "../services/ranks.service";
import TeamRankItemController from "./TeamRankItemController";


class TeamRankItemListController extends React.Component {
    state = {ranks: []};

    componentWillMount() {
        ranksService.listTeams().then((ranks) => {
            this.setState({ranks: ranks})
        })
    }

    render() {
        return (
            <div>
                {this.state.ranks.map((rank) =>
                    <TeamRankItemController
                        key={rank.teamId}
                        rank={rank.rank}
                        nickname={rank.name}
                        earn={rank.earn} />
                )}
            </div>
        )
    }

}

export default TeamRankItemListController