import React from 'react'
import {withRouter} from 'react-router'
import AccountCreatedView from "../views/AccountCreatedView"
import {authenticationService} from '../services/authentication.service'
import translate from "../i18n/translate";
const querystring = require('querystring');


class AccountCreatedController extends React.Component {
    state = {}

    componentWillMount() {
        let key = querystring.parse(this.props.location.search.substring(1)).key
        if(key !== 'nodoi') {
            authenticationService.activate(key)
            // TODO: What to do on activation error???
        }
    }

    render() {
        return (
            <AccountCreatedView>
                <i18n-account-verified>{translate('accountCreated_accountVerified')}</i18n-account-verified>
                <i18n-ready>{translate('accountCreated_ready')}</i18n-ready>
                <i18n-to-earn-eco-coins>{translate('accountCreated_toEarnEcoCoins')}</i18n-to-earn-eco-coins>
                <i18n-scan-the-qr-code>{translate('accountCreated_scanTheQrCode')}</i18n-scan-the-qr-code>
                <i18n-spend-ecos-in-our-marketplace>{translate('accountCreated_spendEcosInOurMarketplace')}</i18n-spend-ecos-in-our-marketplace>
                <i18n-small-changes-add-up>{translate('accountCreated_smallChangesAddUp')}</i18n-small-changes-add-up>
                <start onClick={this.onSignIn}>{translate('accountCreated_start')}</start>
                <skip-tutorial onClick={this.onSignIn}>{translate('accountCreated_skipTutorial')}</skip-tutorial>
            </AccountCreatedView>
        )
    }

    onSignIn = (e) => {
        if(authenticationService.currentUserValue !== null && authenticationService.currentUserValue.token !== null) {
            // We already have a token from SOI flow
            this.props.history.push('/choose-team')
        } else {
            this.props.history.push('/sign-in')
        }
        e.preventDefault()
    }
}

export default withRouter(AccountCreatedController)