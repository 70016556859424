import {translateDef} from "../i18n/translate";

const weekOfDayStrings = [
    '',
    translateDef('date_monday', 'Mon'),
    translateDef('date_tuesday', 'Tue'),
    translateDef('date_wednesday', 'Wed'),
    translateDef('date_thursday', 'Thu'),
    translateDef('date_friday', 'Fri'),
    translateDef('date_saturday', 'Sat'),
    translateDef('date_sunday', 'Sun')
]

export function combineDates(earnDates) {
    if (earnDates.length === 0) {
        return []
    }

    let combined = []
    var acc = {
        startDay: earnDates[0].dayOfWeek,
        endDay: earnDates[0].dayOfWeek,
        startTime: earnDates[0].startTime,
        endTime: earnDates[0].endTime
    }

    for (var i = 1; i < earnDates.length; i++) {
        if (acc.endDay + 1 === earnDates[i].dayOfWeek
            && acc.endTime === earnDates[i].endTime
            && acc.startTime === earnDates[i].startTime) {

            acc.endDay = earnDates[i].dayOfWeek

        } else {
            combined.push(acc)
            acc = {
                startDay: earnDates[i].dayOfWeek,
                endDay: earnDates[i].dayOfWeek,
                startTime: earnDates[i].startTime,
                endTime: earnDates[i].endTime
            }
        }
    }
    combined.push(acc)
    return combined
}

function formatTime(localTime) {
    return localTime.substring(0, 5)
}

export function stringifySingleDate(combinedDate) {
    var acc = ''
    if (combinedDate.startDay === combinedDate.endDay) {
        acc = weekOfDayStrings[combinedDate.startDay]
    } else {
        acc = weekOfDayStrings[combinedDate.startDay] + '-' + weekOfDayStrings[combinedDate.endDay]
    }
    acc += ' ' + formatTime(combinedDate.startTime) + ' - ' + formatTime(combinedDate.endTime);
    return acc
}
