import React from 'react'
import {withRouter} from 'react-router'
import {EMAIL_PATTERN} from "../helpers/patterns"
import HomeAltFormView from "../views/HomeAltFormView";
import translate from "../i18n/translate";
import {authenticationService} from "../services/authentication.service";


class HomeAltFormController extends React.Component {
    state = {
        email: '',
        emailError: 'none',
        submitError: 'none'
    }

    render() {
        return (
            <HomeAltFormView>
                <email onChange={this.setEmail}/>
                <email-error style={{display: this.state.emailError}}>
                    {translate('createAccount_emailError')}
                </email-error>
                <submit onClick={this.submit}>{translate('index_createAccount')}</submit>
                <submit-error style={{display: this.state.submitError}}/>
            </HomeAltFormView>
        )
    }

    setEmail = (e) => {
        this.setState({
            email: e.target.value.trim()
        })
    }

    submit = (e) => {
        this.setState({
            emailError: 'none',
            submitError: 'none'
        })
        let error = false

        if (!EMAIL_PATTERN.test(this.state.email)) {
            this.setState({
                emailError: 'block'
            })
            error = true
        }

        if (error === false) {
            const account = {
                email: this.state.email
            }
            console.log("Submit", account)
            authenticationService.createAccountAlt(this.state.email)
                .then(() => {
                    this.props.history.push({
                        pathname: '/thanks-for-signing-up'
                    })
                })
                .catch((e) => {
                    console.log("ERROR", e)
                    this.setState({
                        submitError: 'block'
                    })
                    if(e.code === 1020) {
                        this.props.history.push({
                            pathname: '/sign-in',
                            state: account
                        })
                    }
                })
        }
        e.preventDefault()
    }

}

export default withRouter(HomeAltFormController)
