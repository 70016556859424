import React from 'react'
import {withRouter} from 'react-router'
import PurchasedView from "../views/PurchasedView"
import {rewardsService} from "../services/rewards.service";
import translate from "../i18n/translate";


class PurchasedController extends React.Component {
    state = {
        reward: {
            voucher: '???'
        },
        purchasePopupState: 'none'
    }

    componentWillMount() {
        rewardsService.get(this.props.match.params.id).then((reward) => {
            this.setState({reward: reward})
        })
    }

    render() {
        return (
            <PurchasedView>
                <close onClick={this.onClose}/>
                <i18n-congratulations>{translate('purchased_congratulations')}</i18n-congratulations>
                <voucher-code>{this.state.reward.voucher}</voucher-code>
                <i18n-show-this-code-to-the-vendor>{translate('purchased_showThisCodeToTheVendor')}</i18n-show-this-code-to-the-vendor>
                <collected-button onClick={this.onCollected}>{translate('purchased_collected')}</collected-button>
                <i18n-want-to-use-the-code-later>{translate('purchased_wantToUseTheCodeLater')}</i18n-want-to-use-the-code-later>

                <purchase-pop-up style={{display: this.state.purchasePopupState}}>
                    <close-pop-up onClick={this.onClosePopup}/>
                    <i18n-are-you-sure-have-collected-your-purchase>{translate(('purchased_areYouSure'))}</i18n-are-you-sure-have-collected-your-purchase>
                    <purchased onClick={this.onPurchased}>{translate('purchased_yes')}</purchased>
                </purchase-pop-up>

            </PurchasedView>
        )
    }

    onClose = (e) => {
        this.props.history.goBack()
        e.preventDefault()
    }

    onCollected = (e) => {
        this.setState({purchasePopupState: 'block'})
        e.preventDefault()
    }

    onClosePopup = (e) => {
        this.setState({purchasePopupState: 'none'})
        e.preventDefault()
    }

    onPurchased = (e) => {
        rewardsService.claim(this.props.match.params.id)
            .then(() => {
                this.props.history.goBack()
            })
        e.preventDefault()
    }
}

export default withRouter(PurchasedController)