const nl = {

    accountCreated_accountVerified: 'Account geverifieerd!',
    accountCreated_ready: 'Klaar om goed te doen en betaald worden?',
    accountCreated_toEarnEcoCoins: 'Om ECO coin te verdienen moet je eerst een duurzame actie in de app invoeren.',
    accountCreated_scanTheQrCode: 'Scan de QR-code om te controleren of de actie heeft plaatsgevonden.',
    accountCreated_spendEcosInOurMarketplace: 'Besteed ECO\'s op onze marktplaats. Haal je beloning op door de aankoopkaart te tonen.',
    accountCreated_smallChangesAddUp: 'Kleine veranderingen hebben een grote impact!',
    accountCreated_start: 'Start met verdienen',
    accountCreated_skipTutorial: 'Handleiding overslaan',

    availability_none: '',
    availability_twicePerDay: 'Tweemaal per dag',
    availability_daily: 'Dagelijks',
    availability_weekly: 'Wekelijks',
    availability_once: 'Een keer',

    bottomNavbar_scan: 'Scannen',
    bottomNavbar_earn: 'Verdienen',
    bottomNavbar_wallet: 'Portemonnee',
    bottomNavbar_spend: 'Besteden',
    bottomNavbar_rank: 'Rang',

    chooseYourTeam_chooseYourTeam: 'Kies je team',
    chooseYourTeam_hint: 'Nog een laatste stap en je kunt beginnen met verdienen!',
    chooseYourTeam_imOnTeam: 'Ik zit in team ...',
    chooseYourTeam_joinTeam: 'Sluit je aan bij het team',
    chooseYourTeam_joinError: 'Fout bij het toetreden tot het team',
    chooseYourTeam_selectTeamError: 'Selecteer een team',

    createAccount_createAccount: 'Maak een account aan',
    createAccount_firstName: 'Voornaam',
    createAccount_firstNameError: 'Vul je voornaam in',
    createAccount_lastName: 'Achternaam',
    createAccount_lastNameError: 'Voer je achternaam in',
    createAccount_email: 'E-mailadres',
    createAccount_emailError: 'Vul je e-mailadres in',
    createAccount_nicknameHint: 'Gebruik je eigen naam of wees creatiever',
    createAccount_nickname: 'Gebruikersnaam',
    createAccount_nicknameError: 'Vul een gebruikersnaam in',
    createAccount_passwordHint: 'Wachtwoord moet minimaal 8 tekens lang zijn, met minimaal een hoofdletter en een cijfer',
    createAccount_password: 'Wachtwoord',
    createAccount_passwordError: 'Wachtwoord moet minimaal 8 tekens lang zijn, met minimaal een hoofdletter en een cijfer',
    createAccount_passwordRepeat: 'herhaal wachtwoord',
    createAccount_passwordRepeatError: 'Wachtwoord komt niet overeen',
    createAccount_alreadyHaveAnAccount: 'Heb je al een account?',
    createAccount_signIn: 'Aanmelden',

    date_monday: 'Ma',
    date_tuesday: 'Di',
    date_wednesday: 'Wo',
    date_thursday: 'Do',
    date_friday: 'Vr',
    date_saturday: 'Za',
    date_sunday: 'Zo',

    earn_earnEcoCoins: 'Verdien ECO coins',
    earn_haveANewIdea: 'Heb je een nieuw idee om ECO\'s te verdienen?',
    earn_letUsKnow: 'Laat ons weten hoe.',

    earnItem_scan: 'Scan QR-code',

    earnVerified_actionVerified: 'Actie geverifieerd!',
    earnVerified_goToMyWallet: 'Ga naar mijn portemonnee',

    forgotPassword_requestPasswordReset: 'Wachtwoord reset aanvragen',
    forgotPassword_email: 'E-mailadres',
    forgotPassword_emailError: 'Vul je e-mailadres in',
    forgotPassword_submit: 'Versturen',
    forgotPassword_signIn: 'Aanmelden',

    index_createAccount: 'Maak een account aan',
    index_alreadyHaveAnAccount: 'Heb je al een account?',
    index_signIn: 'Aanmelden',

    passwordReset_resetYourPassword: 'Stel je wachtwoord opnieuw in',
    passwordReset_password: 'Nieuw wachtwoord',
    passwordReset_passwordError: 'Wachtwoord moet minimaal 8 tekens lang zijn, met hoofdletters en kleine letters en cijfers.',
    passwordReset_passwordRepeat: 'Herhaal nieuw wachtwoord',
    passwordReset_passwordRepeatError: 'Wachtwoord komt niet overeen',
    passwordReset_submit: 'Reset wachtwoord',
    passwordReset_linkExpiredError: 'De link voor het opnieuw instellen van het wachtwoord is verlopen',

    passwordResetComplete_hasBeenReset: 'Je wachtwoord is gereset!',
    passwordResetComplete_signIn: 'Aanmelden',

    passwordResetEmailSent_sent: 'We hebben je een e-mail gestuurd zodat je je wachtwoord opnieuw kunt instellen',

    purchased_congratulations: 'Gefeliciteerd met je aankoop!',
    purchased_showThisCodeToTheVendor: 'Toon deze code aan de verkoper en druk op de knop "Verzameld" hieronder wanneer je de beloning heeft ontvangen.',
    purchased_collected: 'opgehaald',
    purchased_wantToUseTheCodeLater: 'Wil je de code later gebruiken? We slaan het automatisch op in je portemonnee.',
    purchased_areYouSure: 'Weet je zeker dat je je aankoop hebt verzameld?',
    purchased_yes: 'Ja!',

    profile_myProfile: 'Mijn profiel',
    profile_name: 'Naam',
    profile_email: 'E-Mail',
    profile_updateUserName: 'Gebruikersnaam bijwerken',
    profile_userName: 'Gebruikersnaam',
    profile_success: 'Succes',
    profile_updatePassword: 'Vernieuw wachtwoord',
    profile_currentPassword: 'Huidig wachtwoord',
    profile_newPassword: 'Nieuw wachtwoord',
    profile_repeatNewPassword: 'Herhaal nieuw wachtwoord',
    profile_passwordError: 'Voer je wachtwoord in',
    profile_passwordNewError: 'Wachtwoord moet minimaal 8 tekens lang zijn, met hoofdletters en kleine letters en cijfers.',
    profile_passowrdRepeatError: 'Wachtwoord komt niet overeen',
    profile_logOut: 'Uitloggen',
    profile_deleteAccount: 'Account verwijderen',

    qrScan_scanTheQrCode: 'Scan de QR-code\n' +
        'of voer de onderstaande code in',
    qrScan_codeError: 'Richt het op een QR-code van een ECO coin',
    qrScan_cameraError: 'Gebruik Safari om een QR-code met je camera te scannen',
    qrScan_verify: 'Code geverifieerd',
    qrScan_codeInvalidError: 'Code is ongeldig',
    qrScan_codeAvailabilityError: 'De limiet van vandaag is bereikt. Probeer het morgen opnieuw.',
    qrScan_codeTimeError: 'Momenteel niet beschikbaar. Controleer beschikbaarheidstijden.',

    rank_leaderboard: 'Scorebord',
    rank_myRank: 'Mijn rang:',
    rank_myTotalEarned: 'Mijn totaal verdiend:',
    rank_ourRank: 'Onze rang:',
    rank_ourTotalEarned: 'Onze totaal verdiend:',

    rewardItem_soldOut: 'Uitverkocht',
    rewardItem_fewLeft: 'Een paar over',
    rewardItem_buy: 'Koop nu',
    rewardItem_notEnoughCoinsError: 'Je hebt niet genoeg coins',
    rewardItem_outOfStockError: 'Artikel is niet op voorraad',
    rewardItem_userLimitReachedError: 'Je hebt je limiet bereikt om dit item te kopen',
    rewardItem_tooManyTransactionsError: 'Te veel transacties binnen de laatste 5 minuten',

    signIn_signIn: 'Aanmelden',
    signIn_email: 'E-mailadres',
    signIn_password: 'Wachtwoord',
    signIn_emailPasswordError: 'E-mailadres of wachtwoord onjuist',
    signIn_forgotPassword: 'Wachtwoord vergeten?',

    spends_marketplace: 'Marktplaats',
    spends_moreRewardsComingSoon: 'Binnenkort meer beloningen.',

    termsAndConditions_termsAndConditions: 'Voorwaarden',
    termsAndConditions_tacSummary: 'Hier is een samenvatting van de manier waarop we verzamelde gegevens gebruiken. We proberen zo duidelijk mogelijk te zijn, zodat je precies weet hoe dingen werken. Als je vragen heeft, kun je ons bereiken via hello@ecocoin.com.',
    termsAndConditions_point1: '1. Je gegevens worden nooit gedeeld met een derde partij.',
    termsAndConditions_point2: '2. Je hebt het recht om je account te allen tijde te stoppen en te verwijderen.',
    termsAndConditions_point3: '3. Wij behouden ons het recht voor om accounts te sluiten waarvan wij denken dat deze frauduleus zijn of zich niet houden aan de richtlijnen van het platform die zijn vastgelegd in de algemene voorwaarden.',
    termsAndConditions_fullTac: 'Lees hier de volledige voorwaarden.',
    termsAndConditions_acceptTac: 'Ik heb de algemene voorwaarden gelezen',
    termsAndConditions_submit: 'Versturen',
    termsAndConditions_submitError: 'Fout bij registratie. Probeer het later opnieuw.',
    termsAndConditions_submitEmailError: 'E-mailadres is al geregistreerd.',

    verificationEmailSent_thanks: 'Bedankt voor het aanmelden!',
    verificationEmailSent_checkYourEmail: 'Controleer je e-mail om uw account te geverifieerd',

    wallet_hello: 'Hallo',
    wallet_yourCurrentBalanceIs: 'Je huidige saldo is',
    wallet_yourImpact: 'Jouw impact',
    wallet_youHaveSaved: 'Je hebt deze week',
    wallet_ofCo2eThisWeek: 'CO2e bespaard.',
    wallet_recentActivities: 'Recente activiteiten',
    wallet_yourRewards: 'je beloningen om te ontvangen',
    wallet_clickOnYourPurchasedRewards: 'Klik op je gekochte beloningen om ze uit te geven.'

}

export default nl
