function EcoValue(props) {
    const num = (props.value/process.env.REACT_APP_ECOCOIN_DIVIDER).toFixed(0);
    if(props.withSymbol) {
        return num + "E"
    } else {
        return num;
    }
}

export default EcoValue
