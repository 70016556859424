/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js").then(body => body.text()),
  fetch("js/webflow.js").then(body => body.text()),
]

let Controller

class PurchasedView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/PurchasedController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = PurchasedView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== PurchasedView ? transformProxies(this.props.children) : {
      'close': [],
      'i18n-congratulations': [],
      'voucher-code': [],
      'i18n-show-this-code-to-the-vendor': [],
      'collected-button': [],
      'purchase-pop-up': [],
      'close-pop-up': [],
      'i18n-are-you-sure-have-collected-your-purchase': [],
      'purchased': [],
      'i18n-want-to-use-the-code-later': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-gradient-background">
            <div className="af-class-header-container w-container">{map(proxies['close'], props => <a href="wallet.html" {...{...props, className: `af-class-link-block-4 w-inline-block ${props.className || ''}`}}>{props.children}</a>)}</div>
            <div className="af-class-section-8">
              <div className="af-class-container-15 w-container">
                <div className="af-class-div-block-31">
                  {map(proxies['i18n-congratulations'], props => <p {...{...props, className: `af-class-paragraph-4 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Congratulations on your purchase!</React.Fragment>}</p>)}
                  {map(proxies['voucher-code'], props => <p {...{...props, className: `af-class-voucher-code ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>TICKET 1234</React.Fragment>}</p>)}
                  {map(proxies['i18n-show-this-code-to-the-vendor'], props => <p {...{...props, className: `af-class-paragraph-5 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment> show this code to the vendor and hit the "Collected" button bellow when you have received the reward.</React.Fragment>}</p>)}
                </div>
                <div className="af-class-pop-up-wrapper">{map(proxies['collected-button'], props => <a href="#" {...{...props, className: `af-class-button-green w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Collected</React.Fragment>}</a>)}
                  {map(proxies['purchase-pop-up'], props => <div {...{...props, className: `af-class-pop-up-item w-clearfix ${props.className || ''}`}}>{createScope(props.children, proxies => <React.Fragment>
                    {map(proxies['close-pop-up'], props => <div {...{...props, className: `af-class-close-pop-up w-clearfix ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="/images/Group-5.svg" alt="button close for pop up" className="af-class-image-30" /></React.Fragment>}</div>)}<img src="/images/Artboard-82.svg" alt="collect your reward" />
                    {map(proxies['i18n-are-you-sure-have-collected-your-purchase'], props => <p {...{...props, className: `af-class-paragraph-0 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Are you sure you have <br />collected your purchase?</React.Fragment>}</p>)}{map(proxies['purchased'], props => <a href="#" {...{...props, className: `af-class-buy-now-button-on-popup w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Yes!</React.Fragment>}</a>)}</React.Fragment>)}</div>)}
                </div>
              </div>
              <div className="af-class-container-14 w-container">
                {map(proxies['i18n-want-to-use-the-code-later'], props => <p {...{...props, className: `af-class-paragraph-5 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Want to use the code later? We will automatically store it in your wallet.</React.Fragment>}</p>)}
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default PurchasedView

/* eslint-enable */