import { handleResponse, authHeader } from '../helpers'


export const activitiesService = {
    list
}

function list() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(process.env.REACT_APP_ACTIVITIES_LIST, requestOptions)
        .then(handleResponse)
}

