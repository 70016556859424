import React from 'react'
import EcoValue from "../components/EcoValue";
import TeamRankItemView from "../views/TeamRankItemView";


class TeamRankItemController extends React.Component {
    state = {};

    render() {
        const { rank, nickname, earn } = this.props;
        return (
            <TeamRankItemView>
                <rank>{ rank }</rank>
                <name>{ nickname }</name>
                <score><EcoValue value={ earn } withSymbol={true}/></score>
            </TeamRankItemView>
        )
    }

}

export default TeamRankItemController