import React from 'react'
import {ContractData} from "@drizzle/react-components";
import EthWalletDepositForm from "./EthWalletDepositForm";
import EthWalletWithdrawForm from "./EthWalletWithdrawForm";
import {walletService} from "../services/wallet.service";
import EcoValue from "../components/EcoValue";
import {ethereumService} from "../services/ethereum.service";
import AddTokenOption from "./AddTokenOption";


class EthWalletController extends React.Component {

    state = {
        wallet: {
            balance: 0,
            co2Saved: 0
        },
        info: {
            account: ''
        }
    }

    componentWillMount() {
        walletService.overview().then((wallet) => {
            this.setState({wallet: wallet})
        })
        ethereumService.info().then(info => {
            console.log("ETH info", info)
            this.setState({info: info})
        })
    }

    render() {
        return (
            <span>
                <style dangerouslySetInnerHTML={{
                    __html: `
                  @import url(/css/normalize.css);
                  @import url(/css/webflow.css);
                  @import url(/css/ecocoin-app.webflow.css);
                `
                }}/>
                <span className="af-view">
                    <div className="af-class-body-white-background">
                        <div className="af-class-centered-section-too-long">
                            <div className="af-class-my-profile-container w-container">
                                <h1 className="af-class-title">Ethereum Wallet</h1>

                                <div>
                                    <h5 className="af-class-profile-info">
                                        <span>ECOs in ETH Wallet</span>
                                        :&nbsp;
                                        <span className="af-class-text-span-2">
                                            <ContractData
                                                contract="EcoCoin"
                                                method="balanceOf"
                                                methodArgs={[this.props.accounts[0]]}
                                                render={data => (
                                                    <EcoValue value={data}/>
                                                )}
                                            />
                                        </span>
                                    </h5>
                                    <h5 className="af-class-profile-info">
                                        <span>ECOs in App Wallet</span>
                                        :&nbsp;
                                        <span className="af-class-text-span-2">
                                            <EcoValue value={this.state.wallet.balance}/>
                                        </span>
                                    </h5>

                                    <EthWalletDepositForm
                                        contract={this.props.EcoCoin}
                                        appaccount={this.state.info.account}
                                    />
                                    <EthWalletWithdrawForm/>

                                    <AddTokenOption/>

                                </div>
                            </div>
                        </div>
                    </div>
                </span>
            </span>
        )
    }

}

export default EthWalletController
