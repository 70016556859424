import React from 'react'
import {withRouter} from 'react-router'
import translate, {translatePlaceholder, translateValue} from "../i18n/translate"
import {EMAIL_PATTERN, PASSWORD_PATTERN} from "../helpers/patterns"
import CreateAccountForDemoFormView from "../views/CreateAccountForDemoFormView";


class CreateAccountForDemoFormController extends React.Component {
    state = {
        nickname: '',
        nicknameError: 'none',
        email: '',
        emailError: 'none',
        password: '',
        passwordError: 'none',
        passwordRepeat: '',
        passwordRepeatError: 'none'
    }

    render() {
        return (
            <CreateAccountForDemoFormView>
                <email onChange={this.setEmail} {...translatePlaceholder('createAccount_email')}/>
                <email-error
                    style={{display: this.state.emailError}}>{translate('createAccount_emailError')}</email-error>

                <nickname onChange={this.setNickname} {...translatePlaceholder('createAccount_nickname')}/>
                <nickname-error
                    style={{display: this.state.nicknameError}}>{translate('createAccount_nicknameError')}</nickname-error>
                <i18n-password-requirements>{translate('createAccount_passwordHint')}</i18n-password-requirements>
                <password onChange={this.setPassword} {...translatePlaceholder('createAccount_password')}/>
                <password-error
                    style={{display: this.state.passwordError}}>{translate('createAccount_passwordError')}</password-error>
                <password-repeat
                    onChange={this.setPasswordRepeat} {...translatePlaceholder('createAccount_passwordRepeat')}/>
                <password-repeat-error
                    style={{display: this.state.passwordRepeatError}}>{translate('createAccount_passwordRepeatError')}</password-repeat-error>
                <submit onClick={this.submit} {...translateValue('createAccount_createAccount')}/>
                <i18n-already-have-an-account>{translate('createAccount_alreadyHaveAnAccount')}</i18n-already-have-an-account>
                <sign-in onClick={this.onSignIn}>{translate('createAccount_signIn')}</sign-in>
            </CreateAccountForDemoFormView>
        )
    }

    setNickname = (e) => {
        this.setState({
            nickname: e.target.value.trim()
        })
    }
    setEmail = (e) => {
        this.setState({
            email: e.target.value.trim()
        })
    }
    setPassword = (e) => {
        this.setState({
            password: e.target.value
        })
    }
    setPasswordRepeat = (e) => {
        this.setState({
            passwordRepeat: e.target.value
        })
    }
    submit = (e) => {
        this.setState({
            nicknameError: 'none',
            emailError: 'none',
            passwordError: 'none',
            passwordRepeatError: 'none'
        })
        let account = {
            nickname: this.state.nickname,
            email: this.state.email,
            password: this.state.password,
            clientId: -1 // Special case for demo accounts
        }
        let error = false
        if (this.state.nickname === '') {
            this.setState({
                nicknameError: 'block'
            })
            error = true
        }

        if (!EMAIL_PATTERN.test(account.email)) {
            this.setState({
                emailError: 'block'
            })
            error = true
        }
        if (!PASSWORD_PATTERN.test(this.state.password)) {
            this.setState({
                passwordError: 'block'
            })
            error = true
        }
        if (this.state.passwordRepeat === '' || !(this.state.password === this.state.passwordRepeat)) {
            this.setState({
                passwordRepeatError: 'block'
            })
            error = true
        }

        if (error === false) {
            console.log("Submit", this.state)
            this.props.history.push({
                pathname: '/accept-tac',
                state: account
            })
        }
        e.preventDefault()
    }

    onSignIn = (e) => {
        this.props.history.push('/sign-in')
        e.preventDefault()
    }
}

export default withRouter(CreateAccountForDemoFormController)
