import React from 'react'
import {withRouter} from 'react-router'
import {authenticationService} from "../services/authentication.service"
import ProfileView from "../views/ProfileView"
import translate from "../i18n/translate";


class ProfileController extends React.Component {
    state = {};

    // Load data
    componentWillMount() {
        this.setState({
            firstName: authenticationService.currentUserValue.firstName,
            lastName: authenticationService.currentUserValue.lastName,
            nickname: authenticationService.currentUserValue.nickname,
            email: authenticationService.currentUserValue.username
        })
    }

    render() {
        return (
            <ProfileView>
                <i18n-my-profile>{translate('profile_myProfile')}</i18n-my-profile>
                <i18n-name>{translate('profile_name')}</i18n-name>
                <name>{this.state.firstName} {this.state.lastName}</name>
                <i18n-email>{translate('profile_email')}</i18n-email>
                <email>{this.state.email}</email>
                <nickname>{this.state.nickname}</nickname>
                <i18n-update-password>{translate('profile_updatePassword')}</i18n-update-password>
                <logout onClick={this.onLogout}>{translate('profile_logOut')}</logout>
                <delete-account>{translate('profile_deleteAccount')}</delete-account>
            </ProfileView>
        )
    }

    onLogout = (e) => {
        authenticationService.logout()
        this.props.history.push('/')
        e.preventDefault()
    }

}

export default withRouter(ProfileController)