import React from 'react'
import {drizzleConnect} from "@drizzle/react-plugin"
import PropTypes from "prop-types";
import {ethereumService} from "../services/ethereum.service";


class EthWalletWithdrawForm extends React.Component {

    state = {
        amount: '',
    }

    render() {
        return (
            <div className="w-form">
                <form>
                    <label
                        className="af-class-profile-info"
                        htmlFor="deposit-amount"
                    >
                            Withdraw to ETH Wallet
                    </label>
                    <input
                        id="deposit-amount"
                        className="af-class-textfield w-input"
                        placeholder="ECO amount"
                        type="text" 
                        value={this.state.amount}
                        onChange={this.setAmount}
                    />
                    <input
                        className="af-class-button w-button"
                        value="Withdraw"
                        type="submit"
                        onClick={this.submit}
                    />
                </form>
            </div>
        )
    }

    setAmount = (e) => {
        this.setState({
            amount: e.target.value
        })
    }

    submit = (e) => {
        console.log("Submit")
        ethereumService.withdraw(this.props.accounts[0], this.state.amount * process.env.REACT_APP_ECOCOIN_DIVIDER)
        e.preventDefault()
    }

}


EthWalletWithdrawForm.contextTypes = {
    drizzle: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        contracts: state.contracts,
        accounts: state.accounts
    };
};

export default drizzleConnect(EthWalletWithdrawForm, mapStateToProps)

