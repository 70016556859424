import React from 'react'
import WalletActivityItemView from "../views/WalletActivityItemView"
import EcoValue from "../components/EcoValue";


class WalletActivityItemController extends React.Component {
    state = {}

    render() {
        // type is earn, spend, or demurrage
        const { name, amount, type } = this.props
        let amountDisplay;
        if(type === 'earn') {
            amountDisplay = amount
        } else {
            amountDisplay = -amount
        }
        return (
            <WalletActivityItemView>
                <title>{ name }</title>
                <value><EcoValue value={amountDisplay} withSymbol={true}/></value>
            </WalletActivityItemView>
        )
    }

}

export default WalletActivityItemController