import {combineDates, stringifySingleDate} from "../helpers/earnDatesFormatter"
import React from "react"


function EarnDates(props) {
    const {timeConstraint} = props
    return (
        <>
            {combineDates(timeConstraint)
                .map((combinedDate, i) =>
                    <div key={i}>
                        {stringifySingleDate(combinedDate)}
                    </div>
                )}
        </>
    )
}

export default EarnDates