import React from 'react'
import BudgetView from "../views/BudgetView";
import * as moment from "moment";


class BudgetController extends React.Component {

    emissionsUntil2020 = 2406.442531
    emissionsPerYear = 42.332384
    budget2020_15_66 = 235
    budget2020_15_50 = 395
    budget2020_20_80 = 700
    budget2020_20_66 = 985
    budget2020_20_50 = 1315

    state = {
        currentEmissions: 0,
        emissionsPerTick: 0,
    }

    constructor(props) {
        super(props);
        this.state.emissionsPerTick = this.emissionsPerYear / 365 / 24 / 60 / 60
        const secsSince2020 = (new Date().getTime() - new Date(2020, 0, 1).getTime()) / 1000
        const emissionsSince2020 = secsSince2020 * this.state.emissionsPerTick
        this.state.currentEmissions = emissionsSince2020
        this.timer = setInterval(this.countEmissions, 1000)
    }

    countEmissions = () => {
        this.setState({
            currentEmissions: this.state.currentEmissions + this.state.emissionsPerTick
        })
    }

    render() {
        const budgetLeft = this.budget2020_15_66 - this.state.currentEmissions;
        const timeLeft = moment.duration(budgetLeft / this.emissionsPerYear * 365 * 24 * 60 * 60, "seconds")

        return (
            <BudgetView>
                <carbon-budget>{budgetLeft.toLocaleString(undefined, {maximumFractionDigits: 6, minimumFractionDigits: 6})}</carbon-budget>
                <time-years>{timeLeft.years()}</time-years>
                <time-months>{timeLeft.months()}</time-months>
                <time-days>{timeLeft.days()}</time-days>
                <time-hours>{timeLeft.hours()}</time-hours>
                <time-mins>{timeLeft.minutes()}</time-mins>
                <time-secs>{timeLeft.seconds()}</time-secs>
            </BudgetView>
        )
    }
}

export default BudgetController
