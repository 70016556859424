import React from 'react'
import {ranksService} from "../services/ranks.service";
import IndividualRankItemController from "./IndividualRankItemController";


class IndividualRankItemListController extends React.Component {
    state = {ranks: []};

    componentWillMount() {
        ranksService.list().then((ranks) => {
            this.setState({ranks: ranks})
        })
    }

    render() {
        return (
            <div>
                {this.state.ranks.map((rank) =>
                    <IndividualRankItemController
                        key={rank.userId}
                        rank={rank.rank}
                        nickname={rank.nickname}
                        earn={rank.earn} />
                )}
            </div>
        )
    }

}

export default IndividualRankItemListController