/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class ForgotPasswordFormView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/ForgotPasswordFormController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = ForgotPasswordFormView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== ForgotPasswordFormView ? transformProxies(this.props.children) : {
      'email': [],
      'email-error': [],
      'submit': [],
      'sign-in': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="w-form">
            <form id="email-form" name="email-form" data-name="Email Form" className="af-class-form">{map(proxies['email'], props => <input type="email" autoComplete="username" maxLength={256} name="Email" data-name="Email" placeholder="Email address" id="Email-2" required {...{...props, className: `af-class-textfield w-input ${props.className || ''}`}}>{props.children}</input>)}
              {map(proxies['email-error'], props => <p {...{...props, className: `af-class-error-msg ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Enter your email address</React.Fragment>}</p>)}{map(proxies['submit'], props => <input type="submit" value="Submit" data-wait="Please wait..." {...{...props, className: `af-class-button w-button ${props.className || ''}`}}>{props.children}</input>)}{map(proxies['sign-in'], props => <a href="#" {...{...props, className: `af-class-link ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Sign in</React.Fragment>}</a>)}</form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </span>
      </span>
    )
  }
}

export default ForgotPasswordFormView

/* eslint-enable */