/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class CreateAccountFormView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/CreateAccountFormController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = CreateAccountFormView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== CreateAccountFormView ? transformProxies(this.props.children) : {
      'first-name': [],
      'first-name-error': [],
      'last-name': [],
      'last-name-error': [],
      'email': [],
      'email-error': [],
      'nickname': [],
      'nickname-error': [],
      'i18n-password-requirements': [],
      'password': [],
      'password-error': [],
      'password-repeat': [],
      'password-repeat-error': [],
      'submit': [],
      'i18n-already-have-an-account': [],
      'sign-in': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="w-form">
            <form id="email-form" name="email-form" data-name="Email Form" redirect="/verification-email-sent" data-redirect="/verification-email-sent" className="af-class-form">{map(proxies['first-name'], props => <input type="text" autoComplete="given-name" maxLength={256} name="First-Name" data-name="First Name" placeholder="First name" id="First-Name" required {...{...props, className: `af-class-textfield w-input ${props.className || ''}`}}>{props.children}</input>)}
              {map(proxies['first-name-error'], props => <p {...{...props, className: `af-class-error-msg ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Enter your first name</React.Fragment>}</p>)}{map(proxies['last-name'], props => <input type="text" autoComplete="family-name" maxLength={256} name="Last-Name" data-name="Last Name" placeholder="Last name" id="Last-Name" required {...{...props, className: `af-class-textfield w-input ${props.className || ''}`}}>{props.children}</input>)}
              {map(proxies['last-name-error'], props => <p {...{...props, className: `af-class-error-msg ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Enter your last name</React.Fragment>}</p>)}{map(proxies['email'], props => <input type="email" autoComplete="username" maxLength={256} name="Email" data-name="Email" placeholder="Email address" id="Email-2" required {...{...props, className: `af-class-textfield w-input ${props.className || ''}`}}>{props.children}</input>)}
              {map(proxies['email-error'], props => <p {...{...props, className: `af-class-error-msg ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Enter your email address</React.Fragment>}</p>)}
              <h1 className="af-class-password-hint">Use your own name or be more creative</h1>{map(proxies['nickname'], props => <input type="text" maxLength={256} name="Email-3" data-name="Email 3" placeholder="User name" id="Email-3" required {...{...props, className: `af-class-textfield w-input ${props.className || ''}`}}>{props.children}</input>)}
              {map(proxies['nickname-error'], props => <p {...{...props, className: `af-class-error-msg ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Enter a user name</React.Fragment>}</p>)}
              {map(proxies['i18n-password-requirements'], props => <h1 {...{...props, className: `af-class-password-hint ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Password must be at least 8 characters long, with at least one capital letter and a number</React.Fragment>}</h1>)}{map(proxies['password'], props => <input type="password" autoComplete="new-password" maxLength={256} name="Password-4" data-name="Password 4" placeholder="Password" id="Password-4" required {...{...props, className: `af-class-textfield w-input ${props.className || ''}`}}>{props.children}</input>)}
              {map(proxies['password-error'], props => <p {...{...props, className: `af-class-error-msg ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Password must be at least 8 characters long, with at least one capital letter and a number</React.Fragment>}</p>)}{map(proxies['password-repeat'], props => <input type="password" autoComplete="new-password" maxLength={256} name="Repeat-Password" data-name="Repeat Password" placeholder="Repeat password" id="Repeat-Password" required {...{...props, className: `af-class-textfield w-input ${props.className || ''}`}}>{props.children}</input>)}
              {map(proxies['password-repeat-error'], props => <p {...{...props, className: `af-class-error-msg ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Password does not match</React.Fragment>}</p>)}{map(proxies['submit'], props => <input type="submit" value="Create Account" data-wait="Please wait..." {...{...props, className: `af-class-button w-button ${props.className || ''}`}}>{props.children}</input>)}
              <p className="af-class-text-bellow-button">{map(proxies['i18n-already-have-an-account'], props => <span {...props}>{props.children ? props.children : <React.Fragment>Already have an account?</React.Fragment>}</span>)} {map(proxies['sign-in'], props => <a href="sign-in.html" {...{...props, className: `af-class-link-copy ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Sign In</React.Fragment>}</a>)}</p>
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </span>
      </span>
    )
  }
}

export default CreateAccountFormView

/* eslint-enable */