import React from 'react'
import SignInView from '../views/SignInView'
import translate from "../i18n/translate";


class SignInController extends React.Component {
    render() {
        return (
            <SignInView>
                <i18n-sign-in>{translate('signIn_signIn')}</i18n-sign-in>
            </SignInView>
        )
    }
}

export default SignInController