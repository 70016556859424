import React from 'react'
import EarnCardView from "../views/EarnCardView";
import EarnDates from "../components/EarnDates";
import EarnAvailability from "../components/EarnAvailability";
import EcoValue from "../components/EcoValue";
import {authenticationService} from "../services/authentication.service";


class EarnCardController extends React.Component {
    state = {};

    render() {
        const {imageUrl, name, subtitle, location, co2reduction, value, timeConstraint, availability} = this.props;
        let impact = <co2reduction>{co2reduction}</co2reduction>
        if(authenticationService.isTestVariation('A')) {
            impact = ''
        }

        return (
            <EarnCardView>
                <click-area onClick={this.showCard}>
                    <product-image><img src={imageUrl} alt=""/></product-image>
                    <title>{name}</title>
                    <subtitle>{subtitle}</subtitle>
                    <location>{location}</location>
                    { impact }
                    <value><EcoValue value={value} withSymbol={true}/></value>
                    <time-constraint>
                        <EarnDates timeConstraint={timeConstraint}/>
                    </time-constraint>
                    <availability>
                        <EarnAvailability availability={availability}/>
                    </availability>
                </click-area>
            </EarnCardView>
        )
    }

    showCard = (e) => {
        this.props.history.push(`/earn/${this.props.id}`)
        e.preventDefault()
    }
}

export default EarnCardController
