import React from 'react'
import TermsAndConditionsView from '../views/TermsAndConditionsView'
import translate from "../i18n/translate";


class TermsAndConditionsController extends React.Component {
    render() {
        return (
            <TermsAndConditionsView>
                <i18n-terms-and-conditions>{translate('termsAndConditions_termsAndConditions')}</i18n-terms-and-conditions>
            </TermsAndConditionsView>
        )
    }
}

export default TermsAndConditionsController