/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js").then(body => body.text()),
  fetch("js/webflow.js").then(body => body.text()),
]

let Controller

class AccountCreatedView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/AccountCreatedController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = AccountCreatedView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== AccountCreatedView ? transformProxies(this.props.children) : {
      'i18n-account-verified': [],
      'i18n-ready': [],
      'i18n-to-earn-eco-coins': [],
      'i18n-scan-the-qr-code': [],
      'i18n-spend-ecos-in-our-marketplace': [],
      'i18n-small-changes-add-up': [],
      'start': [],
      'skip-tutorial': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body-white-background">
            <div className="af-class-centered-section">
              <div className="af-class-confirmed-container w-container">
                <div className="af-class-div-block-2">
                  <div data-animation="slide" data-nav-spacing={2} data-duration={500} className="af-class-slider-3 w-slider">
                    <div className="w-slider-mask">
                      <div className="w-slide"><img src="images/Artboard-73.svg" alt />
                        <div className="af-class-div-block-30 w-clearfix"><img src="images/Path-4.svg" alt className="af-class-image-29" /></div>
                        {map(proxies['i18n-account-verified'], props => <p {...{...props, className: `af-class-paragraph-6 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Account activated!</React.Fragment>}</p>)}
                        {map(proxies['i18n-ready'], props => <p {...{...props, className: `af-class-paragraph-6 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Ready to do good <br />and get paid?</React.Fragment>}</p>)}
                      </div>
                      <div className="w-slide"><img src="images/Artboard-70.svg" alt className="af-class-image-24" />
                        {map(proxies['i18n-to-earn-eco-coins'], props => <p {...{...props, className: `af-class-paragraph-6 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>To earn ECO coins, first carry out a sustainable missions found in the app.</React.Fragment>}</p>)}
                      </div>
                      <div className="w-slide"><img src="images/Artboard-71.svg" alt className="af-class-image-24" />
                        {map(proxies['i18n-scan-the-qr-code'], props => <p {...{...props, className: `af-class-paragraph-6 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Scan the QR code to verify the<br /> mission has taken place.</React.Fragment>}</p>)}
                      </div>
                      <div className="w-slide"><img src="images/Artboard-72.svg" alt className="af-class-image-24" />
                        {map(proxies['i18n-spend-ecos-in-our-marketplace'], props => <p {...{...props, className: `af-class-paragraph-6 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Spend ECOs in our marketplace. Pick up your reward by showing the purchase card.</React.Fragment>}</p>)}
                      </div>
                      <div className="w-slide"><img src="images/world.svg" alt className="af-class-image-24" />
                        {map(proxies['i18n-small-changes-add-up'], props => <p {...{...props, className: `af-class-paragraph-6 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Small changes add <br />up to a big impact!</React.Fragment>}</p>)}{map(proxies['start'], props => <a href="earn.html" {...{...props, className: `af-class-button w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Start earning</React.Fragment>}</a>)}</div>
                    </div>
                    <div className="w-slider-arrow-left" />
                    <div className="w-slider-arrow-right" />
                    <div className="w-slider-nav w-slider-nav-invert w-round" />
                  </div>
                  <div>{map(proxies['skip-tutorial'], props => <a href="earn.html" {...{...props, className: `af-class-link-2 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Skip tutorial</React.Fragment>}</a>)}</div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default AccountCreatedView

/* eslint-enable */