import React from 'react'
import translate from "../i18n/translate";
import CreateAccountForOrganisationView from "../views/CreateAccountForOrganisationView";


class CreateAccountForOrganisationController extends React.Component {
    state = {}

    render() {
        let introText = <span/>
        if(this.props.introText !== undefined) {
            introText = <intro-text>{this.props.introText}</intro-text>
        }
        return (
            <CreateAccountForOrganisationView emailDomain={this.props.emailDomain}>
                {introText}
                <i18n-create-account>
                    {translate('createAccount_createAccount')}
                </i18n-create-account>
            </CreateAccountForOrganisationView>
        )
    }
}

export default CreateAccountForOrganisationController