/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
  fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js").then(body => body.text()),
  fetch("js/webflow.js").then(body => body.text()),
]

let Controller

class VerificationEmailSentView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/VerificationEmailSentController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = VerificationEmailSentView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== VerificationEmailSentView ? transformProxies(this.props.children) : {
      'close': [],
      'i18n-thanks-for-signing-up': [],
      'i18n-check-your-email': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body-white-background">{map(proxies['close'], props => <a href="create-account.html" {...{...props, className: `af-class-cross-in-yellow-2 w-inline-block ${props.className || ''}`}}>{props.children}</a>)}
            <div className="af-class-centered-section">
              <div className="af-class-confirmed-container w-container">
                <div className="af-class-div-block-2">
                  {map(proxies['i18n-thanks-for-signing-up'], props => <p {...{...props, className: `af-class-paragraph-6 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Thanks for signing up!</React.Fragment>}</p>)}<img src="images/Artboard-75.svg" alt className="af-class-image-25" />
                  {map(proxies['i18n-check-your-email'], props => <p {...{...props, className: `af-class-paragraph-6 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Check your email to <br />verify your account</React.Fragment>}</p>)}
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default VerificationEmailSentView

/* eslint-enable */