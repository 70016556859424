import React from 'react'
import BottomNavbarView from "../views/BottomNavbarView";
import {withRouter} from 'react-router'
import translate from '../i18n/translate'


class BottomNavbarController extends React.Component {
    state = {}

    render() {
        return (
            <BottomNavbarView>
                <scan onClick={this.onScan}>
                    <i18n-scan>{translate('bottomNavbar_scan')}</i18n-scan>
                </scan>
                <earn onClick={this.onEarn}>
                    <i18n-earn>{translate('bottomNavbar_earn')}</i18n-earn>
                </earn>
                <wallet onClick={this.onWallet}>
                    <i18n-wallet>{translate('bottomNavbar_wallet')}</i18n-wallet>
                </wallet>
                <spend onClick={this.onSpend}>
                    <i18n-spend>{translate('bottomNavbar_spend')}</i18n-spend>
                </spend>
                <rank onClick={this.onRank}>
                    <i18n-rank>{translate('bottomNavbar_rank')}</i18n-rank>
                </rank>
            </BottomNavbarView>
        )
    }

    onScan = (e) => {
        this.props.history.push('/qrscan')
        e.preventDefault()
    }

    onEarn = (e) => {
        this.props.history.push('/earn')
        e.preventDefault()
    }

    onWallet = (e) => {
        this.props.history.push('/wallet')
        e.preventDefault()
    }

    onSpend = (e) => {
        this.props.history.push('/spend')
        e.preventDefault()
    }

    onRank = (e) => {
        this.props.history.push('/rank')
        e.preventDefault()
    }

}

export default withRouter(BottomNavbarController)