import React from 'react'
import WalletRewardCardView from "../views/WalletRewardCardView"


class WalletRewardCardController extends React.Component {
    state = {};

    render() {
        const {imageUrl, title, location} = this.props
        return (
            <WalletRewardCardView>
                <click-area onClick={this.showCard}>
                    <product-image><img src={imageUrl} alt=""/></product-image>
                    <title>{title}</title>
                    <location>{location}</location>
                </click-area>
            </WalletRewardCardView>
        )
    }

    showCard = (e) => {
        this.props.history.push(`/purchased/${this.props.id}`)
        e.preventDefault()
    }

}

export default WalletRewardCardController