import React from 'react'
import {drizzleConnect} from "@drizzle/react-plugin"
import PropTypes from "prop-types";


class AddTokenOption extends React.Component {

    constructor(props, context) {
        super(props)
        this.web3 = context.drizzle.web3;
    }

    render() {
        return (
            <button onClick={this.addToken}>Add ECO</button>
        )
    }

    addToken = (e) => {
        const tokenAddress = process.env.REACT_APP_ECO_CONTRACT_ADDRESS
        const tokenSymbol = 'ECO'
        const tokenDecimals = 18
        const tokenImage = 'https://app.ecocoin.com/images/ecocoin-token.png'

        this.web3.currentProvider.sendAsync({
            method: 'wallet_watchAsset',
            params: {
                "type":"ERC20",
                "options":{
                    "address": tokenAddress,
                    "symbol": tokenSymbol,
                    "decimals": tokenDecimals,
                    "image": tokenImage,
                },
            },
            id: Math.round(Math.random() * 100000),
        }, (err, added) => {

            if (added) {
                console.log('Thanks for your interest!')
            } else {
                console.log('Your loss!')
            }

        })
    }

}

AddTokenOption.contextTypes = {
    drizzle: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        contracts: state.contracts,
        accounts: state.accounts
    };
};

export default drizzleConnect(AddTokenOption, mapStateToProps)
