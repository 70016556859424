/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import SpendCardColumnLeftView from './SpendCardColumnLeftView'
import SpendCardColumnRightView from './SpendCardColumnRightView'
import BottomNavbarView from './BottomNavbarView'

const scripts = [
  fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js").then(body => body.text()),
  fetch("js/webflow.js").then(body => body.text()),
]

let Controller

class SpendView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/SpendController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = SpendView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== SpendView ? transformProxies(this.props.children) : {
      'i18n-marketplace': [],
      'balance': [],
      'i18n-more-rewards-coming-soon': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body">
            <div className="af-class-earn-title-section">
              <div className="af-class-header-container w-container">
                {map(proxies['i18n-marketplace'], props => <h1 {...{...props, className: `af-class-title ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Marketplace</React.Fragment>}</h1>)}
                <p className="af-class-eco-balance-total-spend">You have {map(proxies['balance'], props => <span {...props}>{props.children ? props.children : <React.Fragment>950</React.Fragment>}</span>)} ECOs to spend<br /></p>
              </div>
            </div>
            <div className="af-class-section-4">
              <div className="af-class-columns-2 w-row">
                <SpendCardColumnLeftView.Controller />
                <SpendCardColumnRightView.Controller />
              </div>
              <div className="af-class-div-block-12">
                {map(proxies['i18n-more-rewards-coming-soon'], props => <h4 {...{...props, className: `af-class-more-ways-to-earn-text ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>More rewards coming soon.</React.Fragment>}</h4>)}
              </div>
            </div>
            <BottomNavbarView.Controller />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default SpendView

/* eslint-enable */