import React from 'react'
import SpendCardView from "../views/SpendCardView";
import EcoValue from "../components/EcoValue";

class SpendCardController extends React.Component {
    state = {};

    componentWillMount() {
        this.setState({history: this.props.history})
    }

    render() {
        const {imageUrl, name, location, availability, value, displayAvailability} = this.props;
        let availabilityTag = <span/>
        if (displayAvailability) {
            availabilityTag = <availability>{availability}</availability>
        }
        return (
            <SpendCardView>
                <click-area onClick={this.showCard}>
                    <product-image><img src={imageUrl} alt=""/></product-image>
                    <title>{name}</title>
                    <location>{location}</location>
                    {availabilityTag}
                    <price><EcoValue value={value} withSymbol={true}/></price>
                </click-area>
            </SpendCardView>
        )

    }

    showCard = (e) => {
        this.state.history.push(`/spend/${this.props.id}`)
        e.preventDefault()
    }
}

export default SpendCardController