import React from 'react'
import {withRouter} from 'react-router'
import SearchFormView from "../views/SearchFormView";


class EarnSearchFormController extends React.Component {
    state = {};

    render() {
        return (
            <SearchFormView>
                <search-input onChange={this.setSearchInput}/>
                <submit onClick={this.submit}/>
            </SearchFormView>
        )
    }

    setSearchInput = (e) => {
        this.setState({
            search: e.target.value
        })
    };
    submit = (e) => {
        console.log("SEARCH FOR ", this.state.search);
        e.preventDefault()
    }
}

export default withRouter(EarnSearchFormController)
