import React from 'react'
import * as moment from "moment";

class Supply extends React.Component {

    emissionsUntil2020 = 2406.442531
    emissionsPerYear = 42.332384
    budget2020_15_66 = 235
    budget2020_15_50 = 395
    budget2020_20_80 = 700
    budget2020_20_66 = 985
    budget2020_20_50 = 1315

    state = {
        currentEmissions: 0,
        emissionsPerTick: 0,
    }

    constructor(props) {
        super(props);
        this.state.emissionsPerTick = this.emissionsPerYear / 365 / 24 / 60 / 60
        const secsSince2020 = (new Date().getTime() - new Date(2020, 0, 1).getTime()) / 1000
        const emissionsSince2020 = secsSince2020 * this.state.emissionsPerTick
        this.state.currentEmissions = emissionsSince2020
        this.timer = setInterval(this.countEmissions, 1000)
    }

    countEmissions = () => {
        this.setState({
            currentEmissions: this.state.currentEmissions + this.state.emissionsPerTick
        })
    }

    render() {
        const co2Style = {
            textAlign: 'center',
            fontFamily: 'sans-serif',
            fontSize: '3rem'
        }
        const legendStyle = {
            textAlign: 'center',
            fontFamily: 'sans-serif',
            marginBottom: '1rem'
        }
        const ecoStyle = {
            textAlign: 'center',
            fontFamily: 'sans-serif',
            fontSize: '3rem',
            fontWeight: 'bold'
        }
        const budgetLeft = this.budget2020_15_66 - this.state.currentEmissions;
        const timeLeft = moment.duration(budgetLeft / this.emissionsPerYear * 365 * 24 * 60 * 60, "seconds")

        return (
            <div>
                <div style={co2Style}>
                    <GtCO2 value={this.state.currentEmissions + this.emissionsUntil2020}/>
                </div>
                <div style={legendStyle}>
                    GtCO2 since pre industrial times
                </div>
                <div style={ecoStyle}>
                    {((this.emissionsUntil2020 + this.state.currentEmissions) * 1000000000).toLocaleString(undefined, {maximumFractionDigits: 0})}
                </div>
                <div style={legendStyle}>
                    ECOs mined
                </div>
                <div style={co2Style}>
                    <GtCO2 value={budgetLeft}/>
                </div>
                <div style={legendStyle}>
                    GtCO2 left for 66% chance to limit global warming to 1.5°C
                </div>
                <div style={{textAlign: 'center'}}>
                    <TimeLeft duration={timeLeft}/>
                </div>
                <div style={legendStyle}>
                    until budget is depleted with current rate of emissions
                </div>
            </div>
        )
    }
}

function GtCO2(props) {
    return (
        <span>{props.value.toLocaleString(undefined, {maximumFractionDigits: 6, minimumFractionDigits: 6})}</span>
    )
}

function TimeLeft(props) {
    const {duration} = props
    return (
        <span>
            <TimeComponent value={duration.years()} unit="years"/>, <TimeComponent value={duration.months()} unit="months"/>, <TimeComponent value={duration.days()} unit="days"/>, <TimeComponent value={duration.hours()} unit="hours"/>, <TimeComponent value={duration.minutes()} unit="mins"/>, <TimeComponent value={duration.seconds()} unit="secs"/>
        </span>
    )
}

function TimeComponent(props) {
    const timeStyle = {
        fontFamily: 'sans-serif',
        fontSize: '1.5rem'
    }
    const unitStyle = {
        fontFamily: 'sans-serif',
        fontSize: '0.9em'
    }
    const {value, unit} = props
    return (
        <span><span style={timeStyle}>{value}</span> <span style={unitStyle}>{unit}</span></span>
    )
}

export default Supply