import { handleResponse, authHeader } from '../helpers'


export const rewardsService = {
    list,
    get,
    claim
}

function list() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(process.env.REACT_APP_REWARDS_LIST, requestOptions)
        .then(handleResponse)
}

function get(referenceId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(process.env.REACT_APP_REWARD_GET + referenceId, requestOptions)
        .then(handleResponse)
}

function claim(referenceId) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader()
    }

    return fetch(process.env.REACT_APP_REWARD_GET + referenceId, requestOptions)
        .then(handleResponse)
}
