import React from 'react'
import {drizzleConnect, DrizzleProvider} from "@drizzle/react-plugin"
import {LoadingContainer} from "@drizzle/react-components"
import EthWalletController from "./EthWalletController"
import {mapStateToProps} from "./MapStateToProps"
import {drizzleOptions} from "./DrizzleOptions"


const EthWallet = drizzleConnect(EthWalletController, mapStateToProps)

const drizzleApp =
    <DrizzleProvider options={drizzleOptions}>
        <LoadingContainer>
            <EthWallet/>
        </LoadingContainer>
    </DrizzleProvider>

export function EthereumController(props) {
    return drizzleApp
}
