import React from 'react'
import {withRouter} from 'react-router'
import TermsAndConditionsFormView from "../views/TermsAndConditionsFormView"
import {authenticationService} from "../services/authentication.service"
import translate, {translateValue} from "../i18n/translate";


class TermsAndConditionsFormController extends React.Component {
    state = {
        confirm: false,
        submitError: 'none',
        submitEmailError: 'none'
    }

    render() {
        return (
            <TermsAndConditionsFormView>
                <i18n-tac-summary>{translate('termsAndConditions_tacSummary')}</i18n-tac-summary>
                <i18n-point-1>{translate('termsAndConditions_point1')}</i18n-point-1>
                <i18n-point-2>{translate('termsAndConditions_point2')}</i18n-point-2>
                <i18n-point-3>{translate('termsAndConditions_point3')}</i18n-point-3>
                <full-tac onClick={this.fullTac}>{translate('termsAndConditions_fullTac')}</full-tac>
                <confirm-check onChange={this.setConfirm}/>
                <i18n-accept-tac>{translate('termsAndConditions_acceptTac')}</i18n-accept-tac>
                <submit onClick={this.submit} {...translateValue('termsAndConditions_submit')}/>
                <submit-error
                    style={{display: this.state.submitError}}>{translate('termsAndConditions_submitError')}</submit-error>
                <submit-email-error
                    style={{display: this.state.submitEmailError}}>{translate('termsAndConditions_submitEmailError')}</submit-email-error>
            </TermsAndConditionsFormView>
        )
    }

    fullTac = (e) => {
        this.props.history.push({
            pathname: '/terms-and-conditions',
            state: {email: this.props.location.state.email}
        })
        e.preventDefault()
    }

    setConfirm = (e) => {
        this.setState({
            confirm: e.target.checked
        })
        console.log("CONFIRM STAT", e.target.checked)
    }

    submit = (e) => {
        this.setState({
            submitError: 'none',
            submitEmailError: 'none'
        })

        console.log("PROPS", this.props.location.state)

        if (!this.state.confirm) {
            return
        }

        if(this.props.location.state.key === undefined) { // Standard signup flow
            authenticationService.createAccount(
                this.props.location.state.firstName,
                this.props.location.state.lastName,
                this.props.location.state.nickname,
                this.props.location.state.email,
                this.props.location.state.password,
                this.props.location.state.clientId
            ).then(response => {
                if(response.doi) {
                    this.props.history.push('/thanks-for-signing-up')
                } else {
                    this.props.history.push('/activate?key=nodoi')
                }

            }).catch(reason => {
                switch (reason.code) {
                    case 1020:
                        this.setState({
                            submitEmailError: 'block'
                        })
                        break
                    default:
                        this.setState({
                            submitError: 'block'
                        })
                        break
                }
            })

        } else { // Alt signup flow
            authenticationService.createAccountAltFinish(
                this.props.location.state.nickname,
                this.props.location.state.password,
                this.props.location.state.key
            ).then(() => {
                this.props.history.push('/activate?key=nodoi')
            }).catch(reason => {
                this.setState({
                    submitError: 'block'
                })
            })
        }

        e.preventDefault()
    }

}

export default withRouter(TermsAndConditionsFormController)
