import React from 'react'
import PasswordResetView from "../views/PasswordResetView"
import translate from "../i18n/translate";


class PasswordResetController extends React.Component {
    state = {}

    render() {
        return (
            <PasswordResetView>
                <i18n-reset-your-password>{translate('passwordReset_resetYourPassword')}</i18n-reset-your-password>
            </PasswordResetView>
        )
    }
}

export default PasswordResetController