/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class ChooseYourTeamFormView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/ChooseYourTeamFormController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = ChooseYourTeamFormView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== ChooseYourTeamFormView ? transformProxies(this.props.children) : {
      'team': [],
      'select-team-error': [],
      'join-team': [],
      'join-error': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="w-form">
            <form id="email-form" name="email-form" data-name="Email Form" className="af-class-form">{map(proxies['team'], props => <select id="field" name="field" required {...{...props, className: `af-class-select-field w-select ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><option value>I'm on team...</option><option value="First">Team One</option><option value="Second">Team Two</option></React.Fragment>}</select>)}
              {map(proxies['select-team-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Select a team</React.Fragment>}</p>)}{map(proxies['join-team'], props => <input type="submit" value="Join Team" data-wait="Please wait..." {...{...props, className: `af-class-button w-button ${props.className || ''}`}}>{props.children}</input>)}
              {map(proxies['join-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Error joining the team</React.Fragment>}</p>)}
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </span>
      </span>
    )
  }
}

export default ChooseYourTeamFormView

/* eslint-enable */