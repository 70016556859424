import React from 'react'
import {withRouter} from 'react-router'
import PasswordResetFormView from "../views/PasswordResetFormView"
import {authenticationService} from "../services/authentication.service"
import translate, {translatePlaceholder, translateValue} from "../i18n/translate"
import {PASSWORD_PATTERN} from "../helpers/patterns"
const querystring = require('querystring');


class PasswordResetFormController extends React.Component {
    state = {
        key: '',
        password: '',
        passwordError: 'none',
        passwordRepeat: '',
        passwordRepeatError: 'none',
        linkExpiredError: 'none'
    }

    componentWillMount() {
        let key = querystring.parse(this.props.location.search.substring(1)).key
        this.setState({
            key: key
        })
    }

    render() {
        return (
            <PasswordResetFormView>
                <password onChange={this.setPassword} {...translatePlaceholder('passwordReset_password')}/>
                <password-error style={{display: this.state.passwordError}}>{translate('passwordReset_passwordError')}</password-error>
                <password-repeat onChange={this.setPasswordRepeat} {...translatePlaceholder('passwordReset_passwordRepeat')}/>
                <password-repeat-error style={{display: this.state.passwordRepeatError}}>{translate('passwordReset_passwordRepeatError')}</password-repeat-error>
                <submit onClick={this.submit} {...translateValue('passwordReset_submit')}/>
                <link-expired-error style={{display: this.state.linkExpiredError}}>{translate('passwordReset_linkExpiredError')}</link-expired-error>
            </PasswordResetFormView>
        )
    }

    setPassword = (e) => {
        this.setState({
            password: e.target.value
        })
    }
    setPasswordRepeat = (e) => {
        this.setState({
            passwordRepeat: e.target.value
        })
    }
    submit = (e) => {
        this.setState({
            passwordError: 'none',
            passwordRepeatError: 'none',
            linkExpiredError: 'none'
        })
        let error = false
        if (!PASSWORD_PATTERN.test(this.state.password)) {
            this.setState({
                passwordError: 'block'
            })
            error = true
        }
        if (this.state.passwordRepeat === '' || !(this.state.password === this.state.passwordRepeat)) {
            this.setState({
                passwordRepeatError: 'block'
            })
            error = true
        }

        if (error === false) {
            console.log("Submit", this.state)
            authenticationService.resetPassword(this.state.key, this.state.password)
                .then(() => {
                    this.props.history.push('/password-reset-complete')
                })
                .catch(() => {
                    this.setState({
                        linkExpiredError: 'block'
                    })
                })
        }
        e.preventDefault()
    }

}

export default withRouter(PasswordResetFormController)
