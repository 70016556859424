import React from 'react'
import {withRouter} from 'react-router'
import EarnVerifiedView from "../views/EarnVerifiedView"
import translate from "../i18n/translate";


class EarnVerifiedController extends React.Component {
    state = {};

    render() {
        return (
            <EarnVerifiedView>
                <i18n-action-verified>{translate('earnVerified_actionVerified')}</i18n-action-verified>
                <continue onClick={this.onContinue}>{translate('earnVerified_goToMyWallet')}</continue>
                <close onClick={this.onClose} />
            </EarnVerifiedView>
        )
    }

    onContinue = (e) => {
        this.props.history.push('/wallet')
        e.preventDefault()
    }

    onClose = (e) => {
        this.props.history.goBack()
        e.preventDefault()
    }
}

export default withRouter(EarnVerifiedController)