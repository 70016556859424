import React from 'react'
import ChooseYourTeamView from "../views/ChooseYourTeamView"
import translate from "../i18n/translate"


class ChooseYourTeamController extends React.Component {

    render() {
        return (
            <ChooseYourTeamView>
                <i18n-choose-your-team>{translate('chooseYourTeam_chooseYourTeam')}</i18n-choose-your-team>
                <i18n-hint>{translate('chooseYourTeam_hint')}</i18n-hint>
            </ChooseYourTeamView>
        )
    }
}

export default ChooseYourTeamController
