import React from 'react'
import EarnCardController from "./EarnCardController"
import {earnsService} from "../services/earns.service"
import {withRouter} from 'react-router'


class EarnCardListController extends React.Component {
    state = {earns: []}

    componentWillMount() {
        earnsService.list().then((earns) => {
            this.setState({earns: earns})
        })
    }

    render() {
        return (
            <div>
                {this.state.earns.map((earn) =>
                    <EarnCardController
                        key={earn.earnId}
                        id={earn.earnId}
                        name={earn.name}
                        subtitle={earn.subtitle}
                        location={earn.location}
                        value={earn.amount}
                        co2reduction={earn.co2ReductionDesc}
                        imageUrl={process.env.REACT_APP_IMAGE_BASE + earn.imageUrl}
                        timeConstraint={earn.earnDates}
                        availability={earn.usageRestriction}
                        history={this.props.history}
                    />
                )}
            </div>
        )
    }

}

export default withRouter(EarnCardListController)
