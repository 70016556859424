import React from 'react'
import ForgotPasswordView from "../views/ForgotPasswordView"
import translate from "../i18n/translate";


class ForgotPasswordController extends React.Component {
    state = {}

    render() {
        return (
            <ForgotPasswordView>
                <i18n-request-password-reset>{translate('forgotPassword_requestPasswordReset')}</i18n-request-password-reset>
            </ForgotPasswordView>
        )
    }
}

export default ForgotPasswordController