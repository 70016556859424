/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class WalletRewardCardView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/WalletRewardCardController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = WalletRewardCardView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== WalletRewardCardView ? transformProxies(this.props.children) : {
      'click-area': [],
      'product-image': [],
      'title': [],
      'location': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-div-block-21">
            {map(proxies['click-area'], props => <div {...{...props, className: `af-class-div-block-20 ${props.className || ''}`}}>{createScope(props.children, proxies => <React.Fragment>
              {map(proxies['product-image'], props => <div {...props}>{props.children ? props.children : <React.Fragment><img src="images/bethemessenger_drink_fron_dopper.jpg1BB9AD289BBCC067E7C4B9CE9BE0FCF6.jpg" alt className="af-class-image-19" /></React.Fragment>}</div>)}
              <div className="af-class-reward-card-text">
                {map(proxies['title'], props => <h4 {...{...props, className: `af-class-reward-name-on-card ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Bike Repair</React.Fragment>}</h4>)}
                {map(proxies['location'], props => <h4 {...{...props, className: `af-class-reward-location-on-card ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Mac Bike, Amsterdam</React.Fragment>}</h4>)}
              </div>
            </React.Fragment>)}</div>)}
          </div>
        </span>
      </span>
    )
  }
}

export default WalletRewardCardView

/* eslint-enable */