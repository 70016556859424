import React from 'react'
import EcoValue from "../components/EcoValue";
import IndividualRankItemView from "../views/IndividualRankItemView";


class IndividualRankItemController extends React.Component {
    state = {};

    render() {
        const { rank, nickname, earn } = this.props;
        return (
            <IndividualRankItemView>
                <rank>{ rank }</rank>
                <name>{ nickname }</name>
                <score><EcoValue value={ earn } withSymbol={true}/></score>
            </IndividualRankItemView>
        )
    }

}

export default IndividualRankItemController