import React from 'react'
import RankView from "../views/RankView"
import {ranksService} from "../services/ranks.service"
import translate from '../i18n/translate'
import EcoValue from "../components/EcoValue";
import IndividualRankItemListController from "./IndividualRankItemListController";
import TeamRankItemListController from "./TeamRankItemListController";


class RankController extends React.Component {
    state = {
        rank: {
            rank: {
                rank: 0,
                earn: 0,
            },
            total: 0
        },
        team: {
        },
        teamRankVisible: 'none',
        individualRankVisible: 'block'
    };

    componentWillMount() {
        ranksService.user().then((rank) => {
            this.setState({rank: rank})
        })
        ranksService.team().then((rank) => {
            this.setState({team: rank})
        })
    }

    render() {
        let individualViewButton = ''
        let teamViewButton = ''
        let teamRankTab = ''
        if (this.state.team.rank !== undefined && this.state.team.rank !== null) {
            individualViewButton =
                <individual-view-button onClick={this.onIndividualViewButton}/>
            teamViewButton =
                <team-view-button onClick={this.onTeamViewButton}/>
            teamRankTab =
                    <team-rank-tab style={{display: this.state.teamRankVisible}}>
                        <i18n-our-rank>{translate('rank_ourRank')}</i18n-our-rank>
                        <i18n-our-total-earned>{translate('rank_ourTotalEarned')}</i18n-our-total-earned>
                        <our-rank>{this.state.team.rank.rank}/{this.state.team.total}</our-rank>
                        <our-earn><EcoValue value={this.state.team.rank.earn} withSymbol={true}/></our-earn>
                        <TeamRankItemListController/>
                    </team-rank-tab>
        }

        let myRank =
            <my-rank>Only for users</my-rank>
        let myEarn =
            <my-earn>Only for users</my-earn>
        if(this.state.rank.rank !== null) {
            myRank =
                <my-rank>{this.state.rank.rank.rank}/{this.state.rank.total}</my-rank>
            myEarn =
                <my-earn><EcoValue value={this.state.rank.rank.earn} withSymbol={true}/></my-earn>
        }

        return (
            <RankView>
                <i18n-leaderboard>{translate('rank_leaderboard')}</i18n-leaderboard>
                {individualViewButton}
                {teamViewButton}
                {teamRankTab}
                <individual-rank-tab style={{display: this.state.individualRankVisible}}>
                    <i18n-my-rank>{translate('rank_myRank')}</i18n-my-rank>
                    <i18n-my-total-earned>{translate('rank_myTotalEarned')}</i18n-my-total-earned>
                    {myRank}
                    {myEarn}
                    <IndividualRankItemListController/>
                </individual-rank-tab>

            </RankView>
        )
    }

    onIndividualViewButton = (e) => {
        this.setState({
            teamRankVisible: 'none',
            individualRankVisible: 'block'
        })
        e.preventDefault()
    }

    onTeamViewButton = (e) => {
        this.setState({
            teamRankVisible: 'block',
            individualRankVisible: 'none'
        })
        e.preventDefault()
    }
}

export default RankController