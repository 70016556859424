import React from 'react'
import SpendView from "../views/SpendView"
import SpendCardColumnLeftController from './SpendCardColumnLeftController'
import SpendCardColumnRightController from './SpendCardColumnRightController'
import translate from "../i18n/translate";
import {walletService} from "../services/wallet.service";
import EcoValue from "../components/EcoValue";
 

class SpendController extends React.Component {

    state = {
        wallet: {
            balance: 0,
            co2Saved: 0
        }
    }

    componentWillMount() {
        walletService.overview().then((wallet) => {
            this.setState({wallet: wallet})
        })
    }

    render() {
        return (
            <SpendView>
                <i18n-marketplace>{translate('spends_marketplace')}</i18n-marketplace>
                <balance><EcoValue value={this.state.wallet.balance}/></balance>
                <SpendCardColumnLeftController/>
                <SpendCardColumnRightController/>
                <i18n-more-rewards-coming-soon>{translate('spends_moreRewardsComingSoon')}</i18n-more-rewards-coming-soon>
            </SpendView>)
    }
}

export default SpendController