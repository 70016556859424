import {handleResponse, authHeader} from '../helpers'


export const earnsService = {
    list,
    get,
    claim
}

function list() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(process.env.REACT_APP_EARNS_LIST, requestOptions)
        .then(handleResponse)
        .then(earns => {
            return earns.map(earn => fixEarn(earn))
        })
}

function get(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(process.env.REACT_APP_EARNS_GET + id, requestOptions)
        .then(handleResponse)
        .then(earn => fixEarn(earn))
}

function claim(code) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({code: code})
    }
    return fetch(process.env.REACT_APP_EARNS_CLAIM, requestOptions)
        .then(handleResponse)
}

function fixEarn(earn) {
    if(earn.co2ReductionDesc === '' || earn.co2ReductionDesc === undefined) {
        earn.co2ReductionDesc = ' '
    }
    if(earn.subtitle === '' || earn.subtitle === undefined) {
        earn.subtitle = ' '
    }
    return earn;
}
