import { handleResponse, authHeader } from '../helpers'


export const walletService = {
    overview
}

function overview() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(process.env.REACT_APP_WALLET_OVERVIEW, requestOptions)
        .then(handleResponse)
}

