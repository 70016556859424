import {handleResponse, authHeader} from '../helpers'


export const ethereumService = {
    info,
    deposit,
    withdraw
}

function info() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(process.env.REACT_APP_ETH_INFO, requestOptions)
        .then(handleResponse)
}

function deposit(address, amount) {
    console.log("POST deposit", address, amount)
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({address: address, amount: amount})
    }
    return fetch(process.env.REACT_APP_ETH_DEPOSIT, requestOptions)
        .then(handleResponse)
}

function withdraw(address, amount) {
    console.log("POST withdraw", address, amount)
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({address: address, amount: amount})
    }
    return fetch(process.env.REACT_APP_ETH_WITHDRAW, requestOptions)
        .then(handleResponse)
}
