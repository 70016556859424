import React from 'react'
import {withRouter} from 'react-router'
import QrScanView from "../views/QrScanView"


class QrScanController extends React.Component {
    state = {}

    render() {
        return (
            <QrScanView>
                <close onClick={this.onClose} />
            </QrScanView>
        )
    }

    onClose = (e) => {
        this.props.history.goBack()
        e.preventDefault()
    }

}

export default withRouter(QrScanController)