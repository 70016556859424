import React from 'react'
import CreateAccountView from "../views/CreateAccountView"
import translate from "../i18n/translate";


class CreateAccountController extends React.Component {
    state = {}

    render() {
        return (
            <CreateAccountView>
                <i18n-create-account>
                    {translate('createAccount_createAccount')}
                </i18n-create-account>
            </CreateAccountView>
        )
    }
}

export default CreateAccountController