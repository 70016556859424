/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import BottomNavbarView from './BottomNavbarView'

const scripts = [
  fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js").then(body => body.text()),
  fetch("js/webflow.js").then(body => body.text()),
]

let Controller

class EarnItemView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/EarnItemController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = EarnItemView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== EarnItemView ? transformProxies(this.props.children) : {
      'product-image': [],
      'back': [],
      'value': [],
      'title': [],
      'subtitle': [],
      'co2reduction': [],
      'location': [],
      'availability': [],
      'time-constraint': [],
      'description': [],
      'scan': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body-for-earn-card">
            <BottomNavbarView.Controller />
            <div className="af-class-reward-card-header-image-copy">
              <div className="af-class-div-block-22 w-clearfix">
                {map(proxies['product-image'], props => <div {...{...props, className: `af-class-reward-image-box ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/Artboard-1.png" width={100} alt="Product image" srcSet="images/Artboard-1-p-500.png 500w, images/Artboard-1.png 801w" sizes="(max-width: 767px) 100vw, 500px" className="af-class-earn-image-2" /></React.Fragment>}</div>)}{map(proxies['back'], props => <a href="earn.html" {...{...props, className: `af-class-link-block-3 w-inline-block ${props.className || ''}`}}>{props.children}</a>)}
                <div className="af-class-price-circle-green-2">
                  {map(proxies['value'], props => <div {...{...props, className: `af-class-text-block-2 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>50E</React.Fragment>}</div>)}
                </div>
                <div className="af-class-earn-text-card w-clearfix">
                  {map(proxies['title'], props => <h4 {...{...props, className: `af-class-earn-name ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>What a feast!</React.Fragment>}</h4>)}
                  <h4 className="af-class-subheader">{map(proxies['subtitle'], props => <strong {...props}>{props.children ? props.children : <React.Fragment>Eat a healthy meat-free lunch</React.Fragment>}</strong>)}</h4>
                  {map(proxies['co2reduction'], props => <h4 {...{...props, className: `af-class-co2-reduction-2-copy ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>CO2 Reduction of 200g</React.Fragment>}</h4>)}<img src="/images/Location_pin.svg" width={8} alt className="af-class-image-27" />
                  {map(proxies['location'], props => <h4 {...{...props, className: `af-class-location ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Canteen</React.Fragment>}</h4>)}
                  {map(proxies['availability'], props => <h4 {...{...props, className: `af-class-co2-reduction-2-copy ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Availability: Daily</React.Fragment>}</h4>)}
                  {map(proxies['time-constraint'], props => <h4 {...{...props, className: `af-class-time_available ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Monday to Friday<br /></React.Fragment>}</h4>)}
                </div>
                <div className="af-class-div-block-17">
                  {map(proxies['description'], props => <p {...{...props, className: `af-class-earn-card-paragraph-2 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>We use 26% of the energy in our homes for heating, so one of the top tips for saving CO2 is to turn your thermostat down by 1C. This would cut your heating bills by up to 10%, saving you around €40 a year, and the planet around 300kg of CO2!</React.Fragment>}</p>)}{map(proxies['scan'], props => <a id="create-account-button" href="/qrscan" {...{...props, className: `af-class-qr-scan-2 w-button ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Scan QR code</React.Fragment>}</a>)}
                  <div className="af-class-div-block-24" />
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default EarnItemView

/* eslint-enable */