/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class EarnCardView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/EarnCardController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = EarnCardView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== EarnCardView ? transformProxies(this.props.children) : {
      'click-area': [],
      'product-image': [],
      'value': [],
      'title': [],
      'subtitle': [],
      'location': [],
      'co2reduction': [],
      'availability': [],
      'time-constraint': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-earn-card">
            {map(proxies['click-area'], props => <div {...{...props, className: `af-class-div-block-25 w-clearfix ${props.className || ''}`}}>{createScope(props.children, proxies => <React.Fragment>
              {map(proxies['product-image'], props => <div {...{...props, className: `af-class-earn-image-box-2 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/Artboard-1.png" width={100} alt="Product image" srcSet="images/Artboard-1-p-500.png 500w, images/Artboard-1.png 801w" sizes="(max-width: 479px) 100vw, 500px" className="af-class-earn-image" /></React.Fragment>}</div>)}
              <div className="af-class-price-circle-2">
                {map(proxies['value'], props => <div {...{...props, className: `af-class-text-block-2 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>50E</React.Fragment>}</div>)}
              </div>
              <div className="af-class-earn-text w-clearfix">
                {map(proxies['title'], props => <h4 {...{...props, className: `af-class-earn-name ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>What a feast!</React.Fragment>}</h4>)}
                {map(proxies['subtitle'], props => <h4 {...{...props, className: `af-class-subheader ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Eat a healthy meat-free lunch</React.Fragment>}</h4>)}<img src="images/Location_pin.svg" width={8} alt className="af-class-image-27" />
                {map(proxies['location'], props => <h4 {...{...props, className: `af-class-location ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Canteen</React.Fragment>}</h4>)}
                {map(proxies['co2reduction'], props => <h4 {...{...props, className: `af-class-co2-reduction-2 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Reduces 10kg CO2e</React.Fragment>}</h4>)}
                {map(proxies['availability'], props => <h4 {...{...props, className: `af-class-availability-2 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Availability: Daily</React.Fragment>}</h4>)}
                {map(proxies['time-constraint'], props => <h4 {...{...props, className: `af-class-time_available ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Monday to Friday<br /></React.Fragment>}</h4>)}
              </div>
            </React.Fragment>)}</div>)}
          </div>
        </span>
      </span>
    )
  }
}

export default EarnCardView

/* eslint-enable */