import React from 'react'
import SignInFormView from '../views/SignInFormView'
import {authenticationService} from '../services/authentication.service'
import {withRouter} from 'react-router'
import translate, {translatePlaceholder, translateValue} from "../i18n/translate";


class SignInFormController extends React.Component {
    state = {
        alreadyRegisteredMsg: 'none',
        email: '',
        emailPasswordError: 'none'
    }

    componentDidMount() {
        if(this.props.location.state !== undefined) {
            this.setState({
                email: this.props.location.state.email,
                alreadyRegisteredMsg: 'block'
            })
        }
    }

    render() {
        return (
            <SignInFormView>
                <already-registered-msg style={{display: this.state.alreadyRegisteredMsg}}/>
                <email value={this.state.email} onChange={this.setEmail} {...translatePlaceholder('signIn_email')}/>
                <password onChange={this.setPassword} {...translatePlaceholder('signIn_password')}/>
                <login onClick={this.login} {...translateValue('signIn_signIn')}/>
                <email-password-error style={{display: this.state.emailPasswordError}}>{translate('signIn_emailPasswordError')}</email-password-error>
                <forgot-password onClick={this.forgotPassword}>{translate('signIn_forgotPassword')}</forgot-password>
            </SignInFormView>
        )
    }

    setEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }
    setPassword = (e) => {
        this.setState({
            password: e.target.value
        })
    }
    login = (e) => {
        this.setState({
            emailPasswordError: 'none'
        })

        authenticationService.login(this.state.email, this.state.password)
            .then(user => {
                if(!user.hasOwnProperty('teamId') || user.teamId === 0) {
                    this.props.history.push('/choose-team')
                } else {
                    this.props.history.push('/earn')
                }
            })
            .catch(error => {
                    this.setState({
                        emailPasswordError: 'block'
                    })
                    console.log("Error logging in. Error = ", error)
                }
            )
        e.preventDefault()
    }
    forgotPassword = (e) => {
        this.props.history.push('/forgot-password')
        e.preventDefault()
    }
}

export default withRouter(SignInFormController)
