import React from 'react'
import {withRouter} from 'react-router'
import PasswordResetEmailSentView from "../views/PasswordResetEmailSentView";
import translate from "../i18n/translate";


class PasswordResetEmailSentController extends React.Component {
    render() {
        return (
            <PasswordResetEmailSentView>
                <close onClick={this.onClose}/>
                <i18n-we-ve-sent-you-an-email>{translate('passwordResetEmailSent_sent')}</i18n-we-ve-sent-you-an-email>
            </PasswordResetEmailSentView>
        )
    }

    onClose = (e) => {
        this.props.history.push('/')
        e.preventDefault()
    }

}

export default withRouter(PasswordResetEmailSentController)