/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import TeamRankItemListView from './TeamRankItemListView'
import IndividualRankItemListView from './IndividualRankItemListView'
import BottomNavbarView from './BottomNavbarView'

const scripts = [
  fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js").then(body => body.text()),
  fetch("js/webflow.js").then(body => body.text()),
]

let Controller

class RankView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/RankController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = RankView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== RankView ? transformProxies(this.props.children) : {
      'team-view-button': [],
      'individual-view-button': [],
      'i18n-leaderboard': [],
      'team-rank-tab': [],
      'i18n-our-rank': [],
      'i18n-our-total-earned': [],
      'our-rank': [],
      'our-earn': [],
      'individual-rank-tab': [],
      'i18n-my-rank': [],
      'i18n-my-total-earned': [],
      'my-rank': [],
      'my-earn': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body-white-background">
            <div className="af-class-div-block-32">
              <div className="af-class-div-block-33 w-clearfix">{map(proxies['team-view-button'], props => <a data-w-id="179fe726-1e3a-1c4b-1bca-807b787d9f4b" href="#" {...{...props, className: `af-class-link-team-view ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Team <br />View</React.Fragment>}</a>)}{map(proxies['individual-view-button'], props => <a data-w-id="0ac1c1f1-be4b-2cb5-5e65-0124fc291d3e" href="#" {...{...props, className: `af-class-link-individual-view ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Individual <br />View</React.Fragment>}</a>)}</div>
            </div>
            <div className="af-class-section">
              <div className="af-class-sign-up-container w-container">
                {map(proxies['i18n-leaderboard'], props => <h1 {...{...props, className: `af-class-title-leader ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Leaderboard</React.Fragment>}</h1>)}
              </div>
            </div>
            {map(proxies['team-rank-tab'], props => <div {...props}>{createScope(props.children, proxies => <React.Fragment>
              <div className="af-class-our-rankl w-clearfix">
                {map(proxies['i18n-our-rank'], props => <div {...{...props, className: `af-class-leaderboard-our-rank ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Our Rank: <br /></React.Fragment>}</div>)}
                {map(proxies['i18n-our-total-earned'], props => <div {...{...props, className: `af-class-my-total-earned ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Our Total Earned:</React.Fragment>}</div>)}
                {map(proxies['our-rank'], props => <div {...{...props, className: `af-class-leaderboard-our-rank-number ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>1/1<br /></React.Fragment>}</div>)}
                {map(proxies['our-earn'], props => <div {...{...props, className: `af-class-my-total-earned-number ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>700020E</React.Fragment>}</div>)}
              </div>
              <TeamRankItemListView.Controller />
            </React.Fragment>)}</div>)}
            {map(proxies['individual-rank-tab'], props => <div {...props}>{createScope(props.children, proxies => <React.Fragment>
              <div className="af-class-my-rank-info w-clearfix">
                {map(proxies['i18n-my-rank'], props => <div {...{...props, className: `af-class-leaderboard-my-rank ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>My Rank: <br /></React.Fragment>}</div>)}
                {map(proxies['i18n-my-total-earned'], props => <div {...{...props, className: `af-class-my-total-earned ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>My Total Earned:</React.Fragment>}</div>)}
                {map(proxies['my-rank'], props => <div {...{...props, className: `af-class-leaderboard-my-rank-number ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>1/1<br /></React.Fragment>}</div>)}
                {map(proxies['my-earn'], props => <div {...{...props, className: `af-class-my-total-earned-number ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>5020E</React.Fragment>}</div>)}
              </div>
              <IndividualRankItemListView.Controller />
            </React.Fragment>)}</div>)}
            <BottomNavbarView.Controller />
            <div className="af-class-div-block-24" />
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default RankView

/* eslint-enable */