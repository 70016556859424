/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'
import HomeAltFormView from './HomeAltFormView'

const scripts = [
  fetch("https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.4.1.min.220afd743d.js").then(body => body.text()),
  fetch("js/webflow.js").then(body => body.text()),
]

let Controller

class HomeAltView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/HomeAltController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = HomeAltView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== HomeAltView ? transformProxies(this.props.children) : {
      'i18n-already-have-an-account': [],
      'sign-in': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-body-white-background">
            <div className="af-class-centered-section">
              <div className="af-class-container-4 w-container">
                <div className="af-class-div-block"><img src="images/ECO-coin-yellow-green.svg" alt="E" className="af-class-image" /><img src="images/ECO-coin-logo-green.svg" alt="THE ECO COIN" className="af-class-image-2" />
                  <h1 className="af-class-tagline">Do good, get paid</h1>
                </div>
                <HomeAltFormView.Controller />
                <p className="af-class-text-bellow-button">{map(proxies['i18n-already-have-an-account'], props => <span {...props}>{props.children ? props.children : <React.Fragment>Already have an account?</React.Fragment>}</span>)} {map(proxies['sign-in'], props => <a href="sign-in.html" id="sign-in-link" {...{...props, className: `af-class-link-copy ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Sign In</React.Fragment>}</a>)}</p>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    )
  }
}

export default HomeAltView

/* eslint-enable */