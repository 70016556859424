import {handleResponse, authHeader} from '../helpers'


export const spendsService = {
    list,
    get,
    claim
}

function list() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(process.env.REACT_APP_SPENDS_LIST, requestOptions)
        .then(handleResponse)
}

function get(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(process.env.REACT_APP_SPENDS_GET + id, requestOptions)
        .then(handleResponse)
}

function claim(spendId) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({"spendId" : spendId})
    }

    return fetch(process.env.REACT_APP_SPENDS_CLAIM, requestOptions)
        .then(handleResponse)
}
