import React from 'react'
import translate from "../i18n/translate";
import CreateAccountForDemoView from "../views/CreateAccountForDemoView";


class CreateAccountForDemoController extends React.Component {
    state = {}

    render() {
        return (
            <CreateAccountForDemoView>
                <i18n-create-account>
                    {translate('createAccount_createAccount')}
                </i18n-create-account>
            </CreateAccountForDemoView>
        )
    }
}

export default CreateAccountForDemoController