/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [

]

let Controller

class QrScanFormView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/QrScanFormController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = QrScanFormView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    scripts.concat(Promise.resolve()).reduce((loaded, loading) => {
      return loaded.then((script) => {
        new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script)

        return loading
      })
    })
  }

  render() {
    const proxies = Controller !== QrScanFormView ? transformProxies(this.props.children) : {
      'i18n-scan-the-qr-code': [],
      'camera': [],
      'camera-error': [],
      'code': [],
      'code-invalid-error': [],
      'earn-inactive-error': [],
      'code-inactive-error': [],
      'code-limit-error': [],
      'code-time-error': [],
      'server-error': [],
      'verify': [],
    }

    return (
      <span>
        <style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/ecocoin-app.webflow.css);
        ` }} />
        <span className="af-view">
          <div className="af-class-form-block w-form">
            <form id="email-form" name="email-form" data-name="Email Form" className="af-class-activation-code">
              <div className="af-class-header-container w-container">
                {map(proxies['i18n-scan-the-qr-code'], props => <h1 {...{...props, className: `af-class-title-for-scan ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Scan the QR code<br />or enter the code below</React.Fragment>}</h1>)}
                {map(proxies['camera'], props => <div {...{...props, className: `af-class-div-block-16 ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment><img src="images/Group-4.svg" alt className="af-class-image-22" /></React.Fragment>}</div>)}
                {map(proxies['camera-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Please use Safari to scan a QR code<br />with your camera</React.Fragment>}</p>)}
              </div>
              <div className="af-class-qr-scan-section">
                <div className="af-class-verification-code w-container">{map(proxies['code'], props => <input type="number" autoComplete="username" maxLength={256} name="Activation-code" data-name="Activation code" placeholder={123456} id="Activation-code" {...{...props, className: `af-class-earn-code-input w-input ${props.className || ''}`}}>{props.children}</input>)}
                  {map(proxies['code-invalid-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Code is invalid. Check your input.</React.Fragment>}</p>)}
                  {map(proxies['earn-inactive-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>This earn is no longer available.</React.Fragment>}</p>)}
                  {map(proxies['code-inactive-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Code is obsolete. Look for an updated code.</React.Fragment>}</p>)}
                  {map(proxies['code-limit-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Limit reached. Try again later.</React.Fragment>}</p>)}
                  {map(proxies['code-time-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Not available now.&nbsp;Check availability times.</React.Fragment>}</p>)}
                  {map(proxies['server-error'], props => <p {...{...props, className: `af-class-error-msg-center ${props.className || ''}`}}>{props.children ? props.children : <React.Fragment>Server error. Try again later.</React.Fragment>}</p>)}{map(proxies['verify'], props => <input type="submit" value="Verify code" data-wait="Please wait..." {...{...props, className: `af-class-button w-button ${props.className || ''}`}}>{props.children}</input>)}</div>
              </div>
            </form>
            <div className="af-class-success-message w-form-done">
              <div className="af-class-text-block-4">Activation Code Accepted</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </span>
      </span>
    )
  }
}

export default QrScanFormView

/* eslint-enable */