import React from 'react'
import WalletActivityItemController from "./WalletActivityItemController"
import {activitiesService} from "../services/activities.service"


class WalletActivityItemListController extends React.Component {
    state = {activities: []}

    componentWillMount() {
        activitiesService.list().then((activities) => {
            this.setState({activities: activities})
        })
    }

    render() {
        return (
            <div>
                {this.state.activities.map((activity) =>
                    <WalletActivityItemController
                        key={activity.transactionId}
                        name={activity.name}
                        amount={activity.amount}
                        type={activity.type}
                    />
                )}
            </div>
        )
    }

}

export default WalletActivityItemListController