import React from 'react'
import EarnView from "../views/EarnView";
import EarnCardListController from "./EarnCardListController";
import EarnSearchFormController from "./EarnSearchFormController";
import translate from "../i18n/translate";


class EarnController extends React.Component {
    state = {};

    render() {
        return (
            <EarnView>
                <i18n-earn-eco-coins>{translate('earn_earnEcoCoins')}</i18n-earn-eco-coins>
                <EarnSearchFormController/>
                <EarnCardListController/>
                <i18n-have-a-new-idea>{translate('earn_haveANewIdea')}</i18n-have-a-new-idea>
                <i18n-let-us-know>{translate('earn_letUsKnow')}</i18n-let-us-know>
            </EarnView>
        )
    }

}

export default EarnController