import {handleResponse, authHeader} from '../helpers'


export const ranksService = {
    list,
    user,
    listTeams,
    team
}

function list() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(process.env.REACT_APP_RANKS_LIST, requestOptions)
        .then(handleResponse)
}

function user() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(process.env.REACT_APP_RANK_USER, requestOptions)
        .then(handleResponse)
}

function listTeams() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(process.env.REACT_APP_RANKS_LIST_TEAMS, requestOptions)
        .then(handleResponse)
}

function team() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(process.env.REACT_APP_RANKS_TEAM, requestOptions)
        .then(handleResponse)
}

