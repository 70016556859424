import React from 'react'
import {spendsService} from "../services/spends.service";
import SpendCardController from "../controllers/SpendCardController"
import {withRouter} from 'react-router'

class SpendCardColumnRightController extends React.Component {
    state = {};

    componentWillMount() {
        this.setState({ loaded: false });

        spendsService.list().then((spendsData) => {
            let spends = spendsData.filter((element, index) => {
                return index%2 !== 0;
            });

            this.setState({
                spends: spends
            });

            this.setState({ loaded: true });
        })
    }

    render() {
        if(!this.state.loaded)
            return null //TODO display loading animation

        return (
            <span className="af-view">
          <div className="af-class-column-4 w-col w-col-6 w-col-small-6 w-col-tiny-6">
          {this.state.spends.map((spend) =>{
                let availabilityText = spend.usage === 100 ? "Sold Out": spend.usage >= 75 ? "Few left" : null;
                return <div key={spend.spendId}><SpendCardController
                    id={spend.spendId}
                    name={spend.name}
                    location={spend.location}
                    value={spend.amount}
                    imageUrl={process.env.REACT_APP_IMAGE_BASE + spend.imageUrl}
                    availability={availabilityText}
                    displayAvailability={spend.usage>=75}
                    history={this.props.history}
                />
                <br/>
                </div>
              }
            )} 
            </div>
        </span>
        )
    }
}

export default withRouter(SpendCardColumnRightController)