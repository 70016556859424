import React from 'react'
import {withRouter} from 'react-router'
import PasswordResetCompleteView from "../views/PasswordResetCompleteView"
import translate from "../i18n/translate"


class PasswordResetCompleteController extends React.Component {
    render() {
        return (
            <PasswordResetCompleteView>
                <i18n-your-password-has-been-reset>{translate('passwordResetComplete_hasBeenReset')}</i18n-your-password-has-been-reset>
                <close onClick={this.onClose}/>
                <continue onClick={this.onClose}>{translate('passwordResetComplete_signIn')}</continue>
            </PasswordResetCompleteView>
        )
    }

    onClose = (e) => {
        this.props.history.push('/sign-in')
        e.preventDefault()
    }
}

export default withRouter(PasswordResetCompleteController)